import { fromJS, Map } from "immutable";

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getOrganizationId } from "Libs/utils";
import { setIsLoadingState } from "Reducers/utils";

export const getOrganizationProfile = createAsyncThunk(
  "app/organization/profile",
  async ({ organizationId }, { getState }) => {
    const platformLib = await import("Libs/platform");
    const client = platformLib.default;

    const profile = await client.getOrganizationProfile(
      getOrganizationId(getState, organizationId)
    );

    return profile;
  }
);

export const updateOrganizationProfile = createAsyncThunk(
  "app/organization/profile",
  async ({ organizationId, data }, { getState }) => {
    const platformLib = await import("Libs/platform");
    const client = platformLib.default;

    const profile = await client.updateOrganizationProfile(
      getOrganizationId(getState, organizationId),
      data
    );

    return profile;
  }
);

const setError = (state, { error, meta }) =>
  setIsLoadingState(state, meta.arg.organizationId).set(
    "errors",
    error.message
  );

const organizationProfile = createSlice({
  name: "organizationProfile",
  initialState: Map({ data: new Map(), loading: "idle" }),
  reducers: {},
  extraReducers: {
    [getOrganizationProfile.pending]: (state, { meta }) =>
      setIsLoadingState(state, meta.arg.organizationId),
    [getOrganizationProfile.fulfilled]: (state, action) => {
      const { organizationId } = action.meta.arg;

      return setIsLoadingState(state, organizationId, false).setIn(
        ["data", organizationId],
        fromJS(action.payload)
      );
    },
    [getOrganizationProfile.rejected]: (state, action) =>
      setError(state, action)
  }
});

export default organizationProfile.reducer;

export const organizationProfileSelector = (state, props) =>
  state.organizationProfile?.getIn(["data", props.organizationId]);
