import styled from "styled-components";
import { semiBoldAlias } from "Libs/theme";
import { css, ICON, SECTION, getCSSVarString } from "Libs/themes";

export const Layout = styled.li`
  list-style: none;
  span.icon svg {
    max-height: 100%;
    max-width: 100%;
  }
`;

export const NodeLayout = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 24px;

  &.isParent .environment-item {
    opacity: 0.5;
  }

  img + span {
    margin-left: 10px;
  }
  &.depth-0 {
    margin-bottom: 16px;
  }
  &.depth-1 {
    margin-bottom: 20px;
  }
  &.depth-0,
  &.depth-1 {
    .list-bar-left {
      line-height: 32px;
      .icon {
        min-width: 32px;
        width: 32px;
        height: 32px;
        &.root {
          background: ${getCSSVarString(
            ICON,
            "java",
            "background-color"
          )} !important;
          svg path {
            fill:var(--root-environment-icon-fill,var(--icon-snow-fill,var(--icon-snow,var(--snow))));
          }
        }
      }
    }
    .title {
      ${semiBoldAlias};
    }
  }
  &.depth-1 {
    .list-bar-left .icon {
      min-width: 24px;
      width: 24px;
      height: 24px;
      @media (min-width: 1130px) {
        min-width: 32px;
        width: 32px;
        height: 32px;
      }
    }
  }
  .list-bar-left {
    .icon {
      svg path {
        fill:var(--no-children-environment-icon-fill,var(--icon-skye-fill,var(--icon-skye,var(--skye))));
      }
      &.inactive {
        svg path {
          fill:var(--icon-granite-fill,var(--icon-granite,var(--granite)));
        }
      }
    }
  }
  .inactive {
    .title {
      color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
    }
  }
  &.has-children {
    .list-bar-left {
      .icon {
        background-color:var(--icon-skye-background-color,var(--icon-skye,var(--skye)));
        svg path {
          fill:var(--has-children-environment-icon-fill,var(--icon-snow-fill,var(--icon-snow,var(--snow))));
        }
        &.inactive {
          background: ${getCSSVarString(
            SECTION,
            "grey",
            "background-color"
          )} !important;
          svg path {
            fill:var(--icon-granite-fill,var(--icon-granite,var(--granite)));
          }
        }
      }
    }
  }
  @media (max-width: 768px) {
    li.root li.environment-row {
      padding-left: 32px !important;
    }
  }
`;

export const HorizontalLine = styled.div`
  &::after {
    position: absolute;
    content: "";
    background-color:var(--section-periwinkle-grey-background-color,var(--section-periwinkle-grey,var(--periwinkle-grey)));
    height: 1px;
    top: ${props => props.top};
    left: ${props => props.left};
    width: ${props => props.size};
  }
`;

export const VerticalLine = styled.div`
  &::after {
    position: absolute;
    content: "";
    background-color:var(--section-periwinkle-grey-background-color,var(--section-periwinkle-grey,var(--periwinkle-grey)));
    width: 1px;
    left: ${props => props.left};
    top: ${props => props.top};
    height: ${props => props.size};
  }
`;
