import PropTypes from "prop-types";
import React, { useMemo, useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Button from "UI/Button";
import Dropdown from "Components/Dropdown";
import CopyableArea from "Components/CopyableArea";

import * as S from "./SourceOperation.style";
import OperationVariables from "./variable/Variables";

export const RunSourceOps = ({ onClose, sourceOperations, run }) => {
  const [variables, setVariables] = useState([{ name: "", value: "" }]);
  const [expandedCommand, setExpandedCommand] = useState([]);
  const [selectedSourceOps, setSelectedSourceOps] = useState();

  const formatMessage = useIntl().formatMessage;

  const uniqueOperations = useMemo(
    () =>
      sourceOperations?.reduce((uniqueOps, operation) => {
        if (
          uniqueOps.some(op => op.data.operation === operation.data.operation)
        ) {
          return uniqueOps;
        }
        uniqueOps.push(operation);
        return uniqueOps;
      }, []),
    [sourceOperations]
  );

  /**
   * if all source operations has the same operation name defined for them
   * regardless of the operation name or command, assume it has one operation
   * because when any instance of such operation is run, it will run on all the
   * app having similar operation name
   */
  const soureOpsHasSimilarOperations = useMemo(
    () =>
      sourceOperations?.every(operation =>
        sourceOperations.every(
          ops => operation.data.operation === ops.data.operation
        )
      ),
    [sourceOperations]
  );

  useEffect(() => {
    if (soureOpsHasSimilarOperations) {
      setSelectedSourceOps({
        label: sourceOperations[0].data.operation,
        value: sourceOperations[0]
      });
    }
  }, [sourceOperations]);

  const opsWithSameOperationName = useMemo(
    () =>
      sourceOperations?.filter(({ data }) => {
        return data.operation === selectedSourceOps?.value?.data?.operation;
      }),
    [sourceOperations, selectedSourceOps?.label]
  );

  useEffect(() => {
    if (opsWithSameOperationName.length === 1)
      setExpandedCommand(opsWithSameOperationName);
  }, [selectedSourceOps]);

  const onExpandCommand = command => {
    if (expandedCommand.includes(command)) {
      setExpandedCommand(
        expandedCommand.filter(thisCMD => thisCMD !== command)
      );
    } else {
      setExpandedCommand([...expandedCommand, command]);
    }
  };

  const handleSubmit = event => {
    event.preventDefault();
    const vars = variables
      ?.filter(({ name, value }) => name && value)
      .reduce((acc, next) => {
        acc[next.name] = next.value;
        return acc;
      }, {});
    run(selectedSourceOps.value, { env: vars });
  };

  return (
    <S.SourceOperationContainer>
      <form onSubmit={handleSubmit}>
        <S.Heading>
          <FormattedMessage
            id="source_ops.run"
            values={{
              operation: selectedSourceOps
                ? `: ${selectedSourceOps?.value.data.operation}`
                : ""
            }}
          />
        </S.Heading>
        <S.OperationDescription>
          <FormattedMessage
            id={
              selectedSourceOps
                ? "source_ops.runnable"
                : "source_ops.select_operation"
            }
            values={{
              operation: (
                <span className="operation-name">
                  {selectedSourceOps?.value?.data?.operation}
                </span>
              )
            }}
          />
        </S.OperationDescription>

        {!soureOpsHasSimilarOperations && (
          <S.OperationDropdownContainer data-testid="source-operation-dropdown-container">
            <Dropdown
              data-testid="source-operation-dropdown"
              label={formatMessage({ id: "operations" })}
              options={uniqueOperations?.map(value => ({
                label: value.data.operation,
                value
              }))}
              searchable
              onChange={setSelectedSourceOps}
              clearable={false}
              fieldType={true}
              required={true}
              defaultText={formatMessage({ id: "select_operation" })}
            />
          </S.OperationDropdownContainer>
        )}
        {(soureOpsHasSimilarOperations || selectedSourceOps) && (
          <>
            <S.Applications>
              {formatMessage({ id: "applications" })}
            </S.Applications>
            <S.ApplicationHeaderDivider />
          </>
        )}

        <S.ApplicationList className="source-application-list">
          {opsWithSameOperationName?.map((operation, i) => {
            const isExpanded = expandedCommand.includes(operation);

            return (
              <li
                key={i}
                data-testid={`source-application-${operation.data.app}`}
              >
                <S.ApplicationCommandWrapper>
                  <S.SubHeading>{operation.data.app}</S.SubHeading>
                  <div className="code-command-control">
                    <S.ToggleCommandViewButton
                      className={`toggle-operation-command-display ${operation.data.operation}-command-toggle`}
                      data-testid={`${operation.data.app}-command-toggle`}
                      role="button"
                      tabIndex="0"
                      onClick={() => onExpandCommand(operation)}
                    >
                      {isExpanded
                        ? formatMessage({ id: "command.hide" })
                        : formatMessage({ id: "command.view" })}
                    </S.ToggleCommandViewButton>
                    <S.CopyIcon
                      text={operation.data.command}
                      title={formatMessage({ id: "icons.copy_log" })}
                      variant="secondary"
                    />
                  </div>
                </S.ApplicationCommandWrapper>
                {isExpanded && (
                  <S.SourceOpsCommandWrapper
                    data-testid={`application-${operation.data.app}-command-wrapper`}
                    expanded={isExpanded}
                  >
                    <CopyableArea threeLine>
                      {operation.data.command}
                    </CopyableArea>
                  </S.SourceOpsCommandWrapper>
                )}
                <S.AppDivider className="app-divider" />
              </li>
            );
          })}
        </S.ApplicationList>
        <S.SectionDivider />

        <OperationVariables variables={variables} setVariables={setVariables} />

        <S.ButtonWrapper className="modal-buttons">
          <Button type="submit" disabled={!selectedSourceOps}>
            {formatMessage({ id: "run" })}
          </Button>
          <Button variant="secondary" onClick={onClose}>
            {formatMessage({ id: "cancel" })}
          </Button>
        </S.ButtonWrapper>
      </form>
    </S.SourceOperationContainer>
  );
};

RunSourceOps.propTypes = {
  run: PropTypes.func,
  onClose: PropTypes.func,
  sourceOperations: PropTypes.array
};

export default RunSourceOps;
