import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { LiveMessage } from "react-aria-live";
import { getCSSVarString, ICON } from "Libs/themes";

import Portal from "Components/Portal";
import ChevronIcon from "Components/icons/ChevronIcon";
import ListGroup from "Components/ListGroup";
import Label from "Components/fields/Label";
import CloseBtn from "Components/CloseBtn";

import * as S from "./styles";

const ActionDropdown = ({
  ariaLabel,
  children,
  className,
  closeDropDown,
  extraLabel,
  icon,
  iconAfter,
  id,
  inline,
  inverted = false,
  isOpen: defaultOpen,
  label,
  status,
  toggleDropDown,
  withArrow = true,
  withBorder = true,
  withClose,
  withPortal,
  tooltipWidth,
  closeOnContentClick = true
}) => {
  const innerRef = useRef(null);

  const [isOpen, setIsOpen] = useState(defaultOpen);
  const [coords, setCoords] = useState({});

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => document.removeEventListener("click", handleClickOutside);
  });

  useEffect(() => {
    if (defaultOpen !== isOpen) {
      setIsOpen(defaultOpen);
    }
  }, [defaultOpen]);

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("scrolled", toggleOpen);
    } else {
      document.removeEventListener("scrolled", toggleOpen);
    }
    return () => document.removeEventListener("scrolled", toggleOpen);
  }, [isOpen]);

  const handleClickOutside = e => {
    if (!innerRef.current.contains(e.target) && e.target.className !== "copy") {
      if (closeDropDown) {
        closeDropDown();
        return;
      }
      setIsOpen(false);
    }
  };

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const updateCoords = () => {
    if (!withPortal) return;
    const rect = innerRef.current.getBoundingClientRect();
    setCoords({
      left: rect.x + rect.width,
      top: rect.y + window.scrollY + rect.height,
      transform: "translate(-100%, 0)"
    });
  };

  const handleToggle = () => {
    updateCoords();
    if (toggleDropDown) {
      toggleDropDown();
      document.activeElement.blur();
      return;
    }
    toggleOpen();
    document.activeElement.blur();
  };

  const handleClose = () => {
    if (closeOnContentClick) toggleOpen();
  };

  return (
    <S.Wrapper
      id={id}
      ref={innerRef}
      className={`info-button${inline ? " inline-dropdown" : ""}${
        isOpen ? " open-wrapper" : ""
      }${className ? " " + className : ""}`}
      onKeyUp={e => {
        if (e.keyCode === 27 && isOpen) {
          toggleOpen();
        }
        if (e.keyCode === 13 || e.keyCode === 40) {
          toggleOpen();
        }
      }}
      inverted={inverted}
    >
      <LiveMessage
        message={`${label} popup menu has been ${isOpen ? "opened" : "closed"}`}
        aria-live="polite"
      />

      <S.ButtonToggle
        type="button"
        aria-label={ariaLabel}
        id={`${id}-open`}
        withBorder={withBorder}
        onClick={e => {
          e.preventDefault();
          if (status !== "inactive") {
            handleToggle();
          }
        }}
        onKeyUp={e => {
          if (
            (e.keyCode === 27 && isOpen) ||
            e.keyCode === 13 ||
            e.keyCode === 40
          ) {
            handleToggle();
          }
        }}
        className={`${
          icon && !label ? "info-icon action-dropdown" : "action-dropdown"
        }${isOpen ? " open" : ""}${extraLabel ? " with-label" : ""}`}
        aria-haspopup="true"
        aria-expanded={isOpen}
      >
        {icon && !iconAfter && icon}
        {(extraLabel || label) && (
          <div className="text">
            <span className="active" />
            {extraLabel && <Label>{extraLabel}</Label>}
            {label && label}
          </div>
        )}
        {icon && iconAfter && icon}
        {withArrow && (
          <span className="chevron">
            <ChevronIcon color={"var(--icon-slate-fill,var(--icon-slate,var(--slate)))"} />
          </span>
        )}
      </S.ButtonToggle>

      <Portal parent={withPortal ? null : innerRef.current}>
        <S.Window
          id={`${id}-window`}
          className={`tooltip ${id}-window${inline ? " inline" : ""}`}
          open={isOpen}
          style={coords}
          tooltipWidth={tooltipWidth}
        >
          <ListGroup
            aria-label="tooltip content"
            id="tooltip-content-wrapper"
            className="tooltip-content-wrapper"
          >
            {withClose && <CloseBtn onClick={toggleOpen} />}
            <div
              className="tooltip-content"
              onClick={handleClose}
              aria-hidden="true"
            >
              {children}
            </div>
          </ListGroup>
        </S.Window>
      </Portal>
    </S.Wrapper>
  );
};

ActionDropdown.propTypes = {
  ariaLabel: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  closeDropDown: PropTypes.func,
  extraLabel: PropTypes.string,
  tooltipWidth: PropTypes.number,
  icon: PropTypes.node,
  iconAfter: PropTypes.bool,
  id: PropTypes.string,
  inline: PropTypes.bool,
  inverted: PropTypes.bool,
  isOpen: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  status: PropTypes.string,
  toggleDropDown: PropTypes.func,
  withArrow: PropTypes.bool,
  withBorder: PropTypes.bool,
  withClose: PropTypes.bool,
  withPortal: PropTypes.bool,
  closeOnContentClick: PropTypes.bool
};

export default ActionDropdown;
