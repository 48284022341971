import styled from "styled-components";

import {
  getCSSVarString,
  SECTION,
  css,
  LINK,
  MENU,
  ICON,
  INPUT,
  MENU_ITEM
} from "Libs/themes";
import { breakpoints } from "Libs/theme";

export const DropdownLayout = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: flex-start;
  .input-wrapper {
    flex-direction: column;
    align-items: flex-start;
  }
  .select-box {
    width: 264px;
  }
  .select-box,
  .select-box__control,
  .select-box__value-container {
    height: 40px;
    min-height: 40px;
    line-height: 40px;
  }
  .select-box__control {
    background-color:var(--input-ice-background-color,var(--input-ice,var(--ice)));
    border: 1px solid var(--input-ice-border-color,var(--input-ice,var(--ice)));
    border-radius: 2px;
    min-height: 40px;
    &.select-box__control--menu-is-open {
      .select-box__dropdown-indicator svg {
        transform: rotate(180deg);
      }
    }
  }
  .select-box__placeholder {
    font-size: 15px;
    color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    padding-right: 10px;
    max-width: 100%;
    &:first-letter {
      text-transform: uppercase;
    }
  }
  .select-box:hover {
    .select-box__control {
      background-color:var(--input-ice-background-color,var(--input-ice,var(--ice)));
      border: 1px solid var(--input-ice-border-color,var(--input-ice,var(--ice)));
    }
    .select-box__single-value,
    .select-box__placeholder,
    .select-box__dropdown-indicator svg {
      color:var(--link-skye-color,var(--link-skye,var(--skye)));
    }
  }
  .select-box__value-container {
    padding: 0 16px;
    .select-box__single-value {
      height: 40px;
      min-height: 40px;
      line-height: 40px;
    }
  }
  .select-box__indicator-separator {
    display: none;
  }
  .select-box__dropdown-indicator {
    padding: 0 6px 0 0;
    svg {
      color:var(--icon-granite-color,var(--icon-granite,var(--granite)));
    }
  }
  .select-box__control--is-focused {
    border: 1px solid var(--section-skye-dark-border-color,var(--section-skye-dark,var(--skye-dark))) !important;
    box-shadow: 0 0 2px
      var(--section-skye-dark-box-shadow-color,var(--section-skye-dark,var(--skye-dark)));
    outline: none;
  }
  .select-box__control--is-disabled {
    cursor: not-allowed;
    border: none;
    .select-box__indicators {
      display: none;
    }
  }
  .select-box__single-value {
    font-size: 15px;
    margin: 0;
    color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
    &:first-letter {
      text-transform: uppercase;
    }
  }
  .select-box__menu {
    border-radius: 2px;
    background-color:var(--menu-snow-background-color,var(--menu-snow,var(--snow)));
    border: none;
    box-shadow: var(--menu-box-shadow-box-shadow,var(--menu-box-shadow,var(--box-shadow)))
      var(--menu-ebony-light-box-shadow-color,var(--menu-ebony-light,var(--ebony-light)));
    .select-box__menu-list {
      padding: 8px;
    }
    .select-box__option {
      line-height: 22px;
      min-height: 32px;
      display: flex;
      align-items: center;
      color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
      padding: 5px 8px;
      border-radius: 4px;
      font-size: 15px;
      &:first-letter {
        text-transform: uppercase;
      }
      &:hover {
        background-color:var(--menu-item-skye-light-background-color,var(--menu-item-skye-light,var(--skye-light)));
      }
      &:active {
        background: transparent;
        color:var(--menu-skye-color,var(--menu-skye,var(--skye)));
      }
    }
    .select-box__option--is-focused {
      background-color:var(--menu-item-skye-light-background-color,var(--menu-item-skye-light,var(--skye-light)));
    }
    .select-box__option--is-selected {
      background: transparent;
      color:var(--menu-skye-color,var(--menu-skye,var(--skye)));
    }
  }
  .required-message {
    display: inline-block;
    color:var(--section-red-color,var(--section-red,var(--red)));
    font-size: 13px;
    &:first-letter {
      text-transform: uppercase;
    }
  }
  &.outlined {
    .select-box {
      width: 132px;
    }
    .select-box__control,
    .select-box:hover .select-box__control {
      cursor: hover;
      border: 1px solid
        var(--section-skye-light-border-color,var(--section-skye-light,var(--skye-light)));
      border-radius: 4px;
      background: transparent;
      &:focus {
      }
    }
  }
  &.is-disabled {
    cursor: not-allowed;
    label {
      opacity: 0.5;
    }
  }
  &.field-error {
    label,
    .required-message {
      color:var(--section-red-color,var(--section-red,var(--red)));
    }
    .required-notice {
      background-color:var(--section-red-background-color,var(--section-red,var(--red)));
    }
    .select-box__control {
      border: 1px solid var(--section-red-border-color,var(--section-red,var(--red)));
      margin: -1px;
    }
  }
  &.inline {
    .input-wrapper {
      flex-direction: row;
    }
  }
  &.dropdown {
    background-color: transparent;
  }
  ${props =>
    props.responsive &&
    `@media(max-width: ${breakpoints[1]}) {
        width: 100%;
          .select-box {
            width: 100%;
          }
    }
    `};
`;

export const InputLayout = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  align-items: center;
  width: 264px;
  position: relative;
  &.stacked {
    flex-direction: column;
    align-items: flex-start;
    .Select,
    .Select-control,
    .Select-menu-outer {
      width: 100% !important;
    }
    .Select {
      background: #f7f7f7;
    }
  }
  .Select-input:focus {
    background: transparent;
  }
  ${props =>
    props.responsive &&
    `@media(max-width: ${breakpoints[1]}) {
        width: 100%;
    }
    `};
`;
