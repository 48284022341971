import styled from "styled-components";
import Button from "UI/Button";
import { BUTTON, getCSSVarString } from "Libs/themes";

export const SubHeading = styled.h4`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
`;

export const OperationDescription = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
`;

export const VariableWrapper = styled.div``;

export const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const VariableInput = styled.div`
  display: flex;
  gap: 16px;
  .input-field {
    width: 100%;
    flex-shrink: inherit;
  }
  .variable-name {
    flex: 2;
  }
  .variable-value {
    flex: 3;
  }
  .close-button-wrapper {
    display: flex;
    .close {
      position: relative;
      top: 34px;
    }
  }
`;

export const AddVariableHeader = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

export const NewVariableWrapper = styled(Button)`
  display: flex;
  align-items: center;
  font-smooth: antialiazed;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--button-skye-color,var(--button-skye,var(--skye)));
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  gap: 8px;
  cursor: pointer;
  .plus-icon {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
  }
`;
