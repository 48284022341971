/* eslint-disable theming/theme-variable-exist*/

import localForage from "localforage";

import baseTheme from "./theme";

import {
  setCurrentColorMode,
  defaultTheme,
  createStyledTheme,
  injectTheme
} from "@platformsh/ui-kit";

export async function getColorMode() {
  const params = new URLSearchParams(window.location.search);
  const colorModeFromCache = await localForage.getItem("ui_colorscheme");

  const colorMode =
    params.get("theme") || params.get("mode") || colorModeFromCache || "light";

  return colorMode;
}

export async function addUIKitToTheme(themePromise) {
  const theme = await themePromise;
  const uikitTheme = createStyledTheme(baseTheme);
  return { ...theme, ...uikitTheme };
}

function setCurrentTheme(modeName = "light") {
  const html = document.querySelector("html");
  html?.setAttribute("data-theme", modeName);
}

// setupUIKitTheme calls getColorMode to see which mode the app
// needs to use, then selects that mode from the default theme,
// and injects its color values as CSS variables into the document
// UI-Kit uses those CSS variables to theme its components
export async function setupUIKitTheme() {
  let theme = baseTheme;
  let mode = await getColorMode();

  // contrast was a mode in console, but in UI-Kit is a theme
  // this will do it for now, but we need to take contrast
  // apart from mode, in order to be able to have a light/dark contrast theme
  // Until we have a contrast theme we alias contrastTheme to defaultTheme
  const contrastTheme = defaultTheme;

  setCurrentTheme(mode);

  if (mode === "contrast") {
    theme = contrastTheme;
    mode = "light";
  }

  injectTheme(theme);
  setCurrentColorMode(mode);
}

export const NAVBAR = "navbar";
export const SECTION = "section";
export const MENU = "menu";
export const MENU_ITEM = "menu-item";
export const LINK = "link";
export const BUTTON = "button";
export const ICON = "icon";
export const INPUT = "input";
export const HEADER = "header";
export const TAB = "tab";
export const MODAL = "modal";
export const TOOLTIP = "tooltip";
export const BANNER = "banner";
export const DIVIDER = "divider";

export const themeComponentTypes = {
  NAVBAR,
  SECTION,
  MENU,
  MENU_ITEM,
  LINK,
  BUTTON,
  ICON,
  INPUT,
  HEADER,
  TAB,
  MODAL,
  TOOLTIP,
  BANNER,
  DIVIDER
};

// Function to use in the styled component when the css property need to be themable.
// componentName is a uniq specific name that describe the current component
// componentType need the be one of the const above
// cssProperty is the property you want to set
// fallback is the fallback variable you want to use if no variable are setted
export const css = (componentType, fallback, cssProperty, componentName) => {
  let cssString = "";

  if (cssProperty) {
    cssString += `${cssProperty}:`;
  }

  cssString += `${getCSSVarString(
    componentType,
    fallback,
    cssProperty,
    componentName
  )}`;

  return cssString;
};

export function generateColorVariableNames(
  componentName,
  componentType,
  cssProperty,
  fallback
) {
  let variableNames = [`${componentType}-${fallback}`, `${fallback}`];

  if (cssProperty) {
    variableNames.unshift(`${componentType}-${fallback}-${cssProperty}`);
  }

  if (componentName) {
    variableNames.unshift(`${componentName}-${componentType}-${cssProperty}`);
  }

  return variableNames;
}

export const getCSSVarString = (
  componentType,
  fallback,
  cssProperty,
  componentName
) => {
  const variableNames = generateColorVariableNames(
    componentName,
    componentType,
    cssProperty,
    fallback
  );

  let cssVarArray = variableNames.map(v => `var(--${v}`);

  let css = cssVarArray.join(",");
  for (let i = 0; i < cssVarArray.length; i++) {
    css += ")";
  }

  return css;
};
// `var(--${componentName}-${componentType}-${cssProperty}, var(--${componentType}-${cssProperty}, var(--${fallback})))`;

export const getCSSVariable = (cssVar, fallback = "#000000") => {
  const style = getComputedStyle(document.documentElement);
  return style.getPropertyValue(cssVar) || fallback;
};

export const getColor = (
  componentName,
  componentType,
  cssProperty,
  fallback
) => {
  const variableNames = generateColorVariableNames(
    componentName,
    componentType,
    cssProperty,
    fallback
  );

  const style = getComputedStyle(document.documentElement);
  const availableVar = variableNames.find(v =>
    style.getPropertyValue(`--${v}`)
  );

  return style.getPropertyValue(`--${availableVar}`);
};
