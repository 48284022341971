import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import { goToEnvironment } from "Libs/utils";

import { EnvironmentListBar } from "../";

import * as S from "./Node.styles";

const Node = ({
  nodes,
  compactView,
  organizationId,
  visibleNodes,
  titleFilter
}) => {
  const { push } = useHistory();

  if (!nodes || !nodes.length) return false;
  return nodes
    .sort((a, b) => {
      if (a.title.toLowerCase() < b.title.toLowerCase()) return -1;
      if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;
      return 0;
    })
    .filter(node => visibleNodes.includes(node.environmentId))
    .map(node => {
      const orphanedEnv = node?.stub === true;
      const hasChildren = node.children.length > 0;
      const search = (needle, haystack, found = []) => {
        Object.keys(haystack).forEach(key => {
          if (key === needle) {
            found.push(haystack[key]);
            return found;
          }
          if (typeof haystack[key] === "object") {
            search(needle, haystack[key], found);
          }
        });
        return found;
      };
      const childStatuses = search("status", node.children);
      const activeChildren = childStatuses.find(status => {
        return status === "active";
      });
      const inactiveChildren = childStatuses.find(status => {
        return status !== "active";
      });

      let rowPadding = compactView ? "0 0 0 41px" : "0 0 0 48px";
      let verticalSize = "calc(100% - 40px)";
      let verticalLineLeft = compactView ? "53px" : "60px";
      let verticalLineTop = "24px";
      let horizontalLineTop = "13px";
      let horizontalLineLeft = compactView ? "13px" : "12px";
      const horizontalLineWidth = compactView ? "28px" : "38px";
      if (node.isRoot) {
        rowPadding = "0";
      }
      if (node.isRoot) {
        verticalLineLeft = compactView ? "12px" : "16px";
        verticalSize = compactView ? "calc(100% - 26px)" : "calc(100% - 32px)";
        verticalLineTop = compactView ? "24px" : "32px";
      } else {
        if (node.depth < 2) {
          verticalLineLeft = compactView ? "54px" : "64px";
          verticalLineTop = compactView ? "24px" : "32px";
          verticalSize = compactView
            ? "calc(100% - 38px)"
            : "calc(100% - 48px)";
          horizontalLineTop = compactView ? "13px" : "16px";
        }
        if (node.depth < 3) {
          horizontalLineLeft = compactView ? "13px" : "16px";
        }
      }
      if (node.children.length === 1) {
        if (node.depth < 2) {
          verticalSize = compactView ? "26px" : "34px";
        } else {
          verticalSize = compactView ? "26px" : "42px;";
        }
      }

      const isParent = titleFilter
        ? !node.environmentId.includes(titleFilter)
        : false;

      return (
        <S.Layout
          className={`environment-row ${node.isRoot ? "root" : "child"}${
            node.status == "inactive" ? " inactive" : " active"
          }${activeChildren ? " active-children" : ""}${
            inactiveChildren ? " inactive-children" : ""
          } tree-depth-${node.depth} `}
          key={node.title}
          style={{ position: "relative", padding: rowPadding }}
        >
          {!node.isRoot && !orphanedEnv ? (
            <S.HorizontalLine
              size={horizontalLineWidth}
              top={horizontalLineTop}
              left={horizontalLineLeft}
              className="horizontal-line"
            />
          ) : (
            false
          )}
          <S.NodeLayout
            className={`environment-row-item depth-${node.depth} ${
              hasChildren ? "has-children" : ""
            } ${isParent ? "isParent" : ""}`}
          >
            <EnvironmentListBar
              node={node}
              organizationId={organizationId}
              className={`environment-tree-row ${
                node.status === "inactive" ? "inactive" : "active"
              }`}
              titleFilter={titleFilter}
              onClick={() =>
                goToEnvironment(
                  push,
                  organizationId,
                  node.projectId,
                  encodeURIComponent(node.environmentId)
                )
              }
            />
          </S.NodeLayout>
          {hasChildren && (
            <S.VerticalLine
              as="ol"
              size={verticalSize}
              left={verticalLineLeft}
              top={verticalLineTop}
              className="vertical-line"
            >
              <Node
                nodes={node.children}
                organizationId={organizationId}
                compactView={compactView}
                visibleNodes={visibleNodes}
                titleFilter={titleFilter}
              />
            </S.VerticalLine>
          )}
        </S.Layout>
      );
    });
};

Node.propTypes = {
  compactView: PropTypes.bool,
  nodes: PropTypes.array,
  organizationId: PropTypes.string
};

export default Node;
