// Boilerplate source https://github.com/coryhouse/react-slingshot
import "react-hot-loader";
import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { RawIntlProvider } from "react-intl";
import TagManager from "react-gtm-module";
import AppErrorBoundary from "Components/AppErrorBoundary";
import { ConnectedRouter } from "connected-react-router";

import configureStore from "./store/configureStore";
import { history } from "Reducers";

if (!process.env.VENDOR_URL) {
  require("./favicon.ico"); // Tell webpack to import favicon.ico
}
import "core-js/stable";
import "regenerator-runtime/runtime";
import "react-custom-scroll/dist/customScroll.css";
import "platformsh-user-widget/dist/user-widget.css";
import "react-toggle-switch/dist/css/switch.min.css";
import { getUserLanguage } from "Libs/utils";
import { initIntl, getIntl } from "Libs/intl";
import { ANALYTICS_ENABLED } from "Constants/constants";

import App from "./root/pages/Routes";

if (ANALYTICS_ENABLED) {
  const tagManagerArgs = {
    gtmId: process.env.GOOGLE_TAG_MANAGER_ID,
    cookieDomain: "platform.sh"
  };
  TagManager.initialize(tagManagerArgs);
}

const { language, languageWithoutRegionCode } = getUserLanguage();

// Load only the language that we need
// See configureEnvironment.js for implementation
let defaultMessages, intlMessages;
const vendorLocales = process.env.VENDOR_LOCALES || {};
const DEFAULT_LOCALE = "en";
const vendorLocale = vendorLocales[languageWithoutRegionCode]
  ? languageWithoutRegionCode
  : DEFAULT_LOCALE;

try {
  defaultMessages = require(`./locales/${languageWithoutRegionCode}.json`);
  intlMessages = {
    ...defaultMessages,
    ...vendorLocales[vendorLocale]
  };
} catch (err) {
  // If the translation not exist
  defaultMessages = require(`./locales/en.json`);
  intlMessages = {
    ...defaultMessages,
    ...vendorLocales[vendorLocale]
  };
}

initIntl(language, intlMessages);

const store = configureStore();

const fallbackContainer = document.getElementById("fallback");
setTimeout(() => {
  const appContainer = document.getElementById("app");
  if (fallbackContainer && appContainer.hasChildNodes()) {
    fallbackContainer.parentNode
      ? fallbackContainer.parentNode.removeChild(fallbackContainer)
      : "";
  }
}, 1000);

if (process.env.PLATFORM_TREE_ID) {
  //  eslint-disable-next-line no-console
  console.log(`Build ID: %c${process.env.PLATFORM_TREE_ID}`, "color: green");
}

render(
  <AppErrorBoundary>
    <Provider store={store}>
      <RawIntlProvider value={getIntl()}>
        <ConnectedRouter history={history}>
          <React.Suspense fallback={<div />}>
            <App />
          </React.Suspense>
        </ConnectedRouter>
      </RawIntlProvider>
    </Provider>
  </AppErrorBoundary>,
  document.getElementById("app")
);
