import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import { useSelector } from "react-redux";
import ChevronIcon from "Components/icons/ChevronIcon";
import MenuDropdownWrapper from "Components/MenuDropdownWrapper";
import { Map } from "immutable";
import { getUrls } from "Libs/utils";
import MenuLink from "./MenuLink";
import MobileMenu from "./MobileMenu";

import { getCSSVarString, ICON } from "Libs/themes";

import * as S from "./styles";

const UserMenu = ({
  user,
  accountUrl,
  breadcrumbs,
  organizations,
  organizationId
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const $layout = useRef(null);

  let organization = organizations && organizations.get(organizationId);

  useEffect(() => {
    const handleClickOutside = event => {
      if (!ReactDOM.findDOMNode($layout.current).contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside, false);

    return () => document.removeEventListener("click", handleClickOutside);
  }, [$layout]);

  const urls = getUrls({
    accountUrl,
    username: user.username,
    organizationName: organization?.name
  });

  const profile = useSelector(({ profile }) =>
    profile?.getIn(["data", user.username], Map())
  ).toJS();
  const { display_name, picture } = profile;

  return (
    <>
      <MobileMenu breadcrumbs={breadcrumbs} user={user} urls={urls} />
      <S.Layout ref={$layout}>
        <button
          onClick={() => setIsOpen(!isOpen)}
          type="button"
          className={`user-menu-toggle${isOpen ? " open" : ""}${
            user.picture && !user.picture.endsWith("user-default.png")
              ? " picture"
              : ""
          }`}
          aria-label="User menu"
          aria-haspopup="true"
          aria-expanded={isOpen}
        >
          {picture && !picture.endsWith("user-default.png") ? (
            <img src={picture} alt={display_name} />
          ) : (
            display_name
          )}
          <ChevronIcon color={`var(--icon-granite-fill,var(--icon-granite,var(--granite)))`} />
        </button>
        <MenuDropdownWrapper className="menu-dropdown" isOpen={isOpen}>
          <ul>
            {process.env.ENABLE_ORGANIZATION && (
              <S.OrganizationLabel>{organization?.label}</S.OrganizationLabel>
            )}
            {process.env.CUSTOM_VENDOR_CONSOLE_URL &&
              process.env.CUSTOM_VENDOR_CONSOLE_TITLE && (
                <li>
                  <MenuLink
                    label={process.env.CUSTOM_VENDOR_CONSOLE_TITLE}
                    url={process.env.CUSTOM_VENDOR_CONSOLE_URL}
                  />
                </li>
              )}
            {!process.env.ENABLE_ORGANIZATION && (
              <>
                <li>
                  <MenuLink label="Account" url={urls.profileUrl} />
                </li>
                {(process.env.ENABLE_BILLING ||
                  process.env.CUSTOM_BILLING_URL) && (
                  <li>
                    <MenuLink label="Billing" url={urls.billingUrl} />
                  </li>
                )}
              </>
            )}
            {process.env.ENABLE_ORGANIZATION &&
              organizationId &&
              organization &&
              // Only a member or owner of an org would have the self link
              organization?.hasLink("self") && (
                <>
                  <li>
                    <MenuLink
                      label="Settings"
                      url={urls.organizationSettingsUrl}
                    />
                  </li>
                  {organization?.hasLink("members") && (
                    <li>
                      <MenuLink label="Users" url={urls.organizationUsersUrl} />
                    </li>
                  )}
                  {organization?.hasLink("orders") &&
                    (process.env.ENABLE_BILLING ||
                      process.env.CUSTOM_BILLING_URL) && (
                      <li>
                        <MenuLink
                          label="Billing"
                          url={urls.organizationBillingUrl}
                        />
                      </li>
                    )}
                  <S.Separator />
                </>
              )}
            {process.env.ENABLE_ORGANIZATION && (
              <li>
                <MenuLink label="My profile" url={urls.profileUrl} />
              </li>
            )}
            <li>
              <MenuLink label="Support" url={urls.supportUrl} />
            </li>
            <li>
              <MenuLink
                label="Documentation"
                target="_blank"
                rel="noopener noreferrer"
                url={urls.docsUrl}
              />
            </li>
            <li>
              <MenuLink label="Logout" url={urls.logoutUrl} />
            </li>
          </ul>
        </MenuDropdownWrapper>
      </S.Layout>
    </>
  );
};

UserMenu.propTypes = {
  user: PropTypes.object,
  organizations: PropTypes.object,
  accountUrl: PropTypes.string,
  organizationId: PropTypes.string,
  breadcrumbs: PropTypes.node
};

export default UserMenu;
