import Label from "Components/fields/Label";
import { css, SECTION, getCSSVarString, INPUT } from "Libs/themes";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const InputLayout = styled.div`
  width: 100%;
  margin-bottom: 24px;
  position: relative;
  /* Base for label styling */
  [type="checkbox"] {
    position: absolute;
    left: 0;
    top: 0;
    height: 18px;
    width: 18px;
    opacity: 0;
  }
  label {
    position: relative;
    padding-left: 32px;
    cursor: pointer;
    line-height: 24px;
    var(--input-slate-color,var(--input-slate,var(--slate)));
    font-weight: 400;
    font-size: 15px;
    margin-bottom: ${props => (props.error ? "4px" : "8px")};
  }
  /* checkbox aspect */
  label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 3px;
    width: 16px;
    height: 16px;
    border: 1px solid var(--input-granite-border-color,var(--input-granite,var(--granite)));
    background: transparent;
    border-radius: 3px;
    &:hover {
      border-color:var(--input-skye-border-color,var(--input-skye,var(--skye)));
    }
  }
  /* checked mark aspect */
  &.checked label:after {
    content: "";
    position: absolute;
    top: 7px;
    left: 4px;
    transition: all 0.2s;
    background-color:var(--input-skye-background-color,var(--input-skye,var(--skye)));
    background-size: contain;
    height: 10px;
    width: 10px;
    border-radius: 1px;
  }
  /* checked mark aspect changes */
  label:after {
    opacity: 0;
  }
  &.checked label:after {
    opacity: 1;
  }
  /* disabled checkbox */
  [type="checkbox"]:disabled:not(:checked) + label:before,
  [type="checkbox"]:disabled:checked + label:before {
    box-shadow: none;
    border-color: #c9d0e4;
  }
  [type="checkbox"]:disabled + label {
    cursor: not-allowed;
    opacity: 0.5;
  }
  /* accessibility */
  [type="checkbox"]:checked + label:before {
    border-color:var(--input-skye-border-color,var(--input-skye,var(--skye)));
  }
  [type="checkbox"]:checked:focus + label:before,
  [type="checkbox"]:not(:checked):focus + label:before {
    border-color:var(--input-skye-dark-border-color,var(--input-skye-dark,var(--skye-dark)));
    box-shadow: 0 0 2px
      var(--input-skye-dark-box-shadow-color,var(--input-skye-dark,var(--skye-dark)));
  }
  input.changed:checked:focus + label:before,
  input.changed:not(:checked):focus + label:before {
    box-shadow: none;
    border-color:var(--input-skye-border-color,var(--input-skye,var(--skye)));
  }

  /* hover style just for information */
  label:hover:before {
    border-color:var(--input-skye-border-color,var(--input-skye,var(--skye)));
  }
  .description {
    font-size: 15px;
    padding-left: 40px;
    color:var(--input-ebony-light-color,var(--input-ebony-light,var(--ebony-light)));
  }
  .error-alert {
    border: none;
    padding: 4px 0 0;
    .icon {
      display: none;
    }
    .message {
      margin-left: 0;
      font-size: 13px;
      &:first-letter {
        text-transform: uppercase;
      }
    }
  }
  .error-message {
    font-weight: 400;
    display: inline-block;
    color:var(--section-red-color,var(--section-red,var(--red)));
    font-size: 13px;
    &:first-letter {
      text-transform: uppercase;
    }
  }
`;

class CheckboxField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isChanged: false
    };
    this.valueChanged = this.valueChanged.bind(this);
    this.valueReset = this.valueReset.bind(this);
  }
  valueChanged() {
    this.setState({
      isChanged: true
    });
  }
  valueReset() {
    this.setState({
      isChanged: false
    });
  }
  render() {
    const {
      className = "",
      onChange,
      error,
      placeholder,
      value = false,
      label,
      forId,
      id,
      required = false,
      disabled = false
    } = this.props;

    return (
      <InputLayout
        error={error}
        className={`field checkbox-field${value ? " checked" : ""}${
          disabled ? " disabled" : ""
        } ${className}`}
      >
        <div>
          <input
            className={`checkbox${value ? " checked" : ""}${
              this.state.isChanged ? " changed" : ""
            }`}
            id={forId}
            type="checkbox"
            name={forId || id}
            placeholder={placeholder}
            checked={value}
            onChange={onChange}
            onClick={this.valueChanged}
            onBlur={this.valueReset}
            aria-checked={value}
            required={required}
            disabled={disabled}
          />
          <Label id={id ? id : forId + "-label"} htmlFor={forId}>
            {label}
          </Label>
          {error && <div className="error-message">{error}</div>}
        </div>
      </InputLayout>
    );
  }
}

CheckboxField.propTypes = {
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  forId: PropTypes.string.isRequired,
  id: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool
};

export default CheckboxField;
