import styled from "styled-components";
import { css, SECTION } from "Libs/themes";

export const Wrapper = styled.nav`
  .rcs-custom-scroll {
    .rcs-custom-scrollbar {
      opacity: 1;
    }
    .rcs-inner-container {
      padding: 8px 0;
      box-sizing: border-box;
    }
    .rcs-inner-handle {
      background-color:var(--section-dark-tint-background-color,var(--section-dark-tint,var(--dark-tint)));
      width: 4px;
      border-radius: 4px;
      opacity: 1;
    }
  }
  .no-scroll {
    padding: 8px 0;
  }
  ol {
    margin: 0;
    padding: 0;
    li {
      list-style: none;
    }
  }
`;

export const Environments = styled.div`
  width: 100%;
  clear: both;
  border-top: 1px solid #eee;
`;

export const Empty = styled.div`
  color: #1a192b;
  line-height: 32px;
  padding: 0 16px;
  font-size: 14px;
`;
