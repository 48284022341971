import styled from "styled-components";
import UnstyledButtonWrapper from "Components/ButtonWrapper";

export const ModalBody = styled.div`
  color: ${props => props.theme.sectionText};
`;

export const ButtonWrapper = styled(UnstyledButtonWrapper)`
  justify-content: flex-end;
  margin-top: 40px;
`;
