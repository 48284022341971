import { List, Map } from "immutable";
import moment from "moment";
import interpolator from "url-interpolator";

import {
  SAFE_ROLES,
  TEAM_ID_FIELD,
  PROJECT_ID_FIELD,
  ENVIRONMENT_ID_FIELD,
  INTEGRATION_ID_FIELD
} from "Constants/constants";

// Define user's language. Different browsers have the user locale defined
// on different fields on the `navigator` object, so we make sure to account
// for these different by checking all of them
export const getUserLanguage = () => {
  const userLanguage =
    (navigator.languages && navigator.languages[0]) ||
    navigator.language ||
    navigator.userLanguage;
  // Split locales with a region code
  const languageWithoutRegionCode = userLanguage
    .toLowerCase()
    .split(/[_-]+/)[0];
  const supportedLanguages = ["en", "fr"];
  let language = "en-US";

  if (supportedLanguages.indexOf(languageWithoutRegionCode) > -1) {
    language = userLanguage;
  }

  return { language, languageWithoutRegionCode };
};

export const getRouteUrl = route =>
  `${route.scheme}://${route.host}${route.path}`;

export const normalize = (array = [], idFieldName = "id") =>
  array.reduce((accumulator, currentObject) => {
    if (!currentObject) {
      return accumulator;
    }
    accumulator[currentObject[idFieldName]] = currentObject;
    return accumulator;
  }, {});

export const getEnvironmentParents = (environments, childId, parents = []) => {
  let environment = environments.find(env => env.id === childId);
  const parent = environments.find(env => env.id === environment.parent);
  if (parent.parent) {
    return parents.concat([
      getEnvironmentParents(environments, parent.id, parents)
    ]);
  }

  return parents.concat(parent);
};

export const immutableListToMap = (list, keyFieldName, valueFieldName) =>
  list.reduce((map, obj) => {
    const key = obj.get(keyFieldName);
    map[key] = obj.get(valueFieldName);

    return map;
  }, {});

export const getOrganizationDescriptionId = (
  getState,
  projectDescriptionId
) => {
  if (getState().project.size > 0) {
    const orgId = getState().project.getIn(
      ["orgByProjectId", projectDescriptionId],
      false
    );
    return orgId;
  }
};

export const getProjectDescriptionId = (getState, projectId) => {
  const me = getState().app.get("me", new Map());
  const project = me
    .get("projects", new List())
    .find(p => p.get("id") === projectId);

  if (!project) {
    return project;
  }

  return project.get(PROJECT_ID_FIELD);
};

export const getProjectId = (getState, projectDescriptionId) => {
  const me = getState().app?.get("me", new Map());
  const project = me
    ?.get("projects", List())
    ?.find(p => p.get(PROJECT_ID_FIELD) === projectDescriptionId);

  if (!project) {
    return projectDescriptionId;
  }

  return project.get("id");
};

export const getOrganizationId = (getState, organizationDescriptionId) => {
  const organization = getState().organization.getIn(
    ["orgByDescriptionField", organizationDescriptionId],
    Map()
  );
  /*const organizations = getState().organization.get("data");

  const organization = organizations.find(p => {
    return p[ORGANIZATION_ID_FIELD] === organizationDescriptionId;
  });*/

  if (!organization) {
    return organization;
  }

  return organization.id;
};

export const getTeamId = (getState, teamDescriptionId) => {
  const me = getState().app.get("me", new Map());
  const team = me
    .get("teams", new List())
    .find(p => p.get(TEAM_ID_FIELD) === teamDescriptionId);

  if (!team) {
    return team;
  }

  return team.get("id");
};

export const getEnvironmentId = (
  getState,
  organizationDescriptionId,
  projectDescriptionId,
  environmentDescriptionId
) => {
  const environment = getState().environment.getIn(
    [
      "data",
      organizationDescriptionId,
      projectDescriptionId,
      environmentDescriptionId
    ],
    new Map()
  );

  if (!environment) {
    return environment;
  }

  return environment.id;
};

export const getEnvironmentDescriptionId = (
  getState,
  organizationDescriptionId,
  projectDescriptionId,
  environmentId
) => {
  const environment = getState()
    ?.environment?.getIn(
      ["data", organizationDescriptionId, projectDescriptionId],
      new List()
    )
    ?.find(e => e.id === environmentId);

  if (!environment) {
    return false;
  }

  return environment[ENVIRONMENT_ID_FIELD];
};

export const getIntegrationId = (
  getState,
  organizationDescriptionId,
  projectDescriptionId,
  integrationDescriptionId
) => {
  const integration = getState().integration.getIn(
    [
      "data",
      organizationDescriptionId,
      projectDescriptionId,
      integrationDescriptionId
    ],
    new Map()
  );

  if (!integration) {
    return integration;
  }

  return integration.id;
};

export const getIntegrationDescriptionId = (
  getState,
  organizationDescriptionId,
  projectDescriptionId,
  integrationId
) => {
  const integration = getState()
    ?.integration?.getIn(
      ["data", organizationDescriptionId, projectDescriptionId],
      new List()
    )
    ?.find(i => i.id === integrationId);

  if (!integration) {
    return false;
  }

  return integration[INTEGRATION_ID_FIELD];
};

export const getEnvironmentURI = (organizationId, projectId, environmentId) =>
  `/${organizationId}/${projectId}/${encodeURIComponent(environmentId)}`;

export const goToEnvironment = (
  pushFct,
  organizationDescriptionId,
  projectDescriptionId,
  environmentDescriptionId
) =>
  pushFct(
    getEnvironmentURI(
      organizationDescriptionId,
      projectDescriptionId,
      environmentDescriptionId
    )
  );

export const goToProject = (
  pushFct,
  organizationDescriptionId,
  projectDescriptionId
) => pushFct(`/${organizationDescriptionId}/${projectDescriptionId}`);

export const goToOrganization = (pushFct, organizationDescriptionId) =>
  pushFct(`/${organizationDescriptionId}`);

export const getEnvironmentGitCommand = (
  project,
  environment,
  marchineName,
  type
) => {
  if (!project || !project.repository) {
    return false;
  }

  if (marchineName === "magento") {
    marchineName = "magento-cloud";
  }

  // Escape an environment (Git branch) name as a shell argument. We do not
  // need to worry about double escaping, because Git branch names cannot
  // contain backslashes.
  function escapeNameAsShellArg(str) {
    return str.replace(/(["$`' ()])/g, "\\$1");
  }

  if (type === "cli" && environment) {
    return `${marchineName} get ${project.id} -e ${escapeNameAsShellArg(
      environment.name
    )}`;
  }

  if (type === "cli-project") {
    return `${marchineName} get ${project.id}`;
  }
  // const first = project.title.replace(" ", "-");
  const title = project.title.replace(/[^\w\s]/gi, "").replace(/\s+/g, "-");
  if (type === "git-project") {
    return `git clone ${project.repository.url} ${title}`;
  }

  return `git clone --branch ${escapeNameAsShellArg(environment.name)} ${
    project.repository.url
  } ${title}`;
};

export const getGitRemoteCommand = (project, marchineName) => {
  if (!project || !project.repository) {
    return false;
  }

  if (marchineName === "magento") {
    marchineName = "magento-cloud";
  }

  return `git remote add ${marchineName} ${project.repository.url}`;
};

export const getOrganizationDescriptionIdFromProject = (
  project,
  organizations = {}
) => {
  if (!project) {
    return false;
  }
  if (project?.owner_info?.type === "user") {
    return project.owner_info?.username;
  }

  return organizations[
    project?.organization || project?.organization_id || project?.owner
  ]?.name;
};

export const checkGoLive = (project, organization) => {
  if (organization?.hasLink && !organization.hasLink("estimate-subscription")) {
    return "no-permission";
  }
  if (!project) return false;
  if (project.subscription?.plan === "development") {
    if (project.data?._links["#subscription_change"]) {
      return "development";
    } else {
      return "no-permission";
    }
  } else {
    if (!project.default_domain) {
      if (project.data?._links["#edit"]) {
        return "domain";
      } else {
        return "no-permission";
      }
    } else {
      return "live";
    }
  }
};

export const constructLegacyUrl = (url, project) => {
  const params = url.split("/").filter(param => param !== "");

  if (!project) {
    return `${process.env.ACCOUNTS_URL}/`;
  }

  const projectUrl = `https://${project.region}/projects/${project.id}`;
  if (!params[2]) {
    return projectUrl;
  }
  const isProjectSettings = params[2] === "settings";
  if (isProjectSettings) {
    return `${projectUrl}/edit`;
  }

  const environmentName = params[2];
  const isEnvironmentSettings = params[3] === "settings";
  const environmentUrl = `${projectUrl}/environments/${environmentName}`;
  if (!isEnvironmentSettings) {
    return environmentUrl;
  } else {
    return `${environmentUrl}/edit`;
  }
};

// If there's no address or login settings, then HTTP access is disabled.
export const httpStatusDisplay = environment => {
  if (
    environment &&
    environment.http_access &&
    environment.http_access.is_enabled
  ) {
    if (
      environment.http_access.addresses.length === 0 &&
      Object.entries(environment.http_access.basic_auth).length === 0
    ) {
      return false;
    } else {
      return true;
    }
  }
  return false;
};

export const formatAsCurrency = (amount, currency) => {
  if (typeof currency === "undefined") {
    throw new Error("A currency must be defined");
  }
  const { language } = getUserLanguage();
  const format = new Intl.NumberFormat(language, {
    style: "currency",
    currency,
    minimumFractionDigits: 2
  });
  return format.format(amount);
};

export const isPlainObject = obj => {
  return Object.prototype.toString.call(obj) === "[object Object]";
};

const sortProjectListByOwner = (a, b, settings, organizations) => {
  if (a.owner_info.type === "user") {
    if (a.owner_info.username && b.owner_info.username) {
      if (settings.sortOrder === "descend") {
        if (a.owner_info.username > b.owner_info.username) return -1;
        if (a.owner_info.username < b.owner_info.username) return 1;
        return 0;
      }
      if (a.owner_info.username < b.owner_info.username) return -1;
      if (a.owner_info.username > b.owner_info.username) return 1;
      return 0;
    }
  }

  if (a?.organization_id) {
    const o1 = organizations[a?.organization_id];
    const o2 = organizations[b?.organization_id];
    const o1Label = o1?.label.toLowerCase();
    const o2Label = o2?.label.toLowerCase();
    if (o1Label && o2Label) {
      if (settings.sortOrder === "descend") {
        if (o1Label > o2Label) return -1;
        if (o1Label < o2Label) return 1;
        return 0;
      }
      if (o1Label < o2Label) return -1;
      if (o1Label > o2Label) return 1;
      return 0;
    }
  }

  return 0;
};

export const sortProjectList = (
  a,
  b,
  settings,
  organizations,
  { idFieldName = "id", titleFieldName = "title" } = {}
) => {
  if (
    settings.sortType === "owner" &&
    ((a.owner_info && b.owner_info) || (a.organization && b.organization))
  ) {
    return sortProjectListByOwner(a, b, settings, organizations);
  }
  if (settings.sortType === "region" && a.region_label && b.region_label) {
    if (settings.sortOrder === "descend") {
      if (a.region_label.toLowerCase() > b.region_label.toLowerCase())
        return -1;
      if (a.region_label.toLowerCase() < b.region_label.toLowerCase()) return 1;
      return 0;
    }
    if (a.region_label.toLowerCase() < b.region_label.toLowerCase()) return -1;
    if (a.region_label.toLowerCase() > b.region_label.toLowerCase()) return 1;
    return 0;
  }

  const aId = a[idFieldName];
  const bId = b[idFieldName];
  if (settings.sortType === "id" && aId && bId) {
    const aIdLowercase = aId.toLowerCase();
    const bIdLowercase = bId.toLowerCase();
    if (settings.sortOrder === "descend") {
      if (aIdLowercase > bIdLowercase) return -1;
      if (aIdLowercase < bIdLowercase) return 1;
      return 0;
    }
    if (aIdLowercase < bIdLowercase) return -1;
    if (aIdLowercase > bIdLowercase) return 1;
    return 0;
  }
  if (settings.sortType === "plan" && a.plan && b.plan) {
    if (settings.sortOrder === "descend") {
      if (a.plan.toLowerCase() > b.plan.toLowerCase()) return -1;
      if (a.plan.toLowerCase() < b.plan.toLowerCase()) return 1;
      return 0;
    }
    if (a.plan.toLowerCase() < b.plan.toLowerCase()) return -1;
    if (a.plan.toLowerCase() > b.plan.toLowerCase()) return 1;
    return 0;
  }
  if (settings.sortType === "date" && a.created_at && b.created_at) {
    if (settings.sortOrder === "descend") {
      if (a.created_at > b.created_at) return -1;
      if (a.created_at < b.created_at) return 1;
      return 0;
    }
    if (a.created_at < b.created_at) return -1;
    if (a.created_at > b.created_at) return 1;
    return 0;
  }
  if (settings.sortOrder === "descend" && a.title && b.title) {
    if (a.title.toLowerCase() > b.title.toLowerCase()) return -1;
    if (a.title.toLowerCase() < b.title.toLowerCase()) return 1;
    return 0;
  }

  const aTitle = a[titleFieldName];
  const bTitle = b[titleFieldName];
  if (aTitle && bTitle) {
    const aTitleLowerCase = aTitle.toLowerCase();
    const bTitleLowerCase = bTitle.toLowerCase();
    if (aTitleLowerCase < bTitleLowerCase) return -1;
    if (aTitleLowerCase > bTitleLowerCase) return 1;
  }
  if (!aTitle && bTitle) {
    return -1;
  }
  return 0;
};

export const sortSubscriptionList = (a, b, settings, organizations) => {
  return sortProjectList(a, b, settings, organizations, {
    idFieldName: "project_id",
    titleFieldName: "project_title"
  });
};

export const sortBy = (list, field) => {
  return list.sort((a, b) => {
    return a[field].localeCompare(b[field]);
  });
};

export const pluralizeWord = (noun, count, suffix = "s") =>
  `${noun}${count !== 1 ? suffix : ""}`;

export const dateAsUTC = date => {
  return moment(date).utc().format();
};

export const isGitUrl = url => {
  if (!url) {
    return false;
  }

  return (
    url.startsWith("git://") || url.endsWith(".git") || !url.includes(".yaml")
  );
};

export const getRegionLabel = (regionLabel = "") => {
  const title = regionLabel.split("[")[0].trim();
  const titleSuffix = regionLabel.substring(
    regionLabel.lastIndexOf("[") + 1,
    regionLabel.lastIndexOf("]")
  );

  if (title.length < 1) {
    return {
      title: regionLabel,
      suffix: ""
    };
  }
  return {
    title,
    suffix: titleSuffix
  };
};

export const hasSafeRole = roles => {
  return roles && roles.some(role => SAFE_ROLES.includes(role));
};

export const orderTotal = (components, currency) => {
  const total = Object.keys(components).reduce((total, component) => {
    return (total = total + components[component].amount);
  }, 0);

  return formatAsCurrency(total, currency);
};

export const isDefined = variable => {
  return !(typeof variable === "undefined" || variable === null);
};

export const isJson = str => {
  if (typeof str !== "string") return false;
  try {
    const result = JSON.parse(str);
    return ["[object Object]", "[object Array]"].includes(
      Object.prototype.toString.call(result)
    );
  } catch (err) {
    return false;
  }
};

export const hasHtml = str => {
  const htmlRegex = new RegExp("<.+?>");
  return htmlRegex.test(str);
};

export const interpolateURL = (templateURL, params) => {
  return interpolator(templateURL, params);
};

export const isProjectOwner = (project, me, organizations = {}) => {
  if (project?.owner_info?.type === "user") {
    return project?.owner === me?.id;
  }

  if (project?.organization_id) {
    // If the organization object has a billing link, this user has some owner rights
    return !!organizations[project.organization_id]?.getLink("billing");
  }

  return false;
};

export const isSubscriptionOwner = (subscription, me, organizations) => {
  if (subscription?.owner_info?.type === "user") {
    return subscription?.owner === me?.id;
  }

  if (organizations && subscription?.organization_id) {
    // If the organization object has a billing link, this user has some owner rights
    return !!organizations[subscription.organization_id].getLink("billing");
  }

  return false;
};

export const getOwnerInfoLabel = (subcription, organizations) => {
  if (
    !process.env.ENABLE_ORGANIZATION ||
    subcription?.owner_info?.type === "user"
  ) {
    return subcription?.owner_info?.display_name;
  }

  return organizations[subcription?.organization_id]?.label;
};

export const getOwnerInfoName = (project, organizations) => {
  if (
    !process.env.ENABLE_ORGANIZATION ||
    project?.owner_info?.type === "user"
  ) {
    return project?.owner_info?.username;
  }

  return organizations[project?.organization || project?.organization_id]?.name;
};

export const getTrial = (me, project, organizations) => {
  if (project?.organization_id) {
    return organizations[project.organization_id]?.trial;
  }

  return me?.trial;
};

export const arrayToObject = array =>
  array.reduce((result, [key, value]) => ({ ...result, [key]: value }), {});

export const excludeKeys = (object, keys) =>
  arrayToObject(Object.entries(object).filter(([key]) => !keys.includes(key)));

export const capitalize = s => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const getUrls = ({ accountUrl, username, organizationName }) => {
  let profileUrl = `/-/users/${username}/settings`;
  let billingUrl = `/-/users/${username}/billing`;
  let supportUrl = `/-/users/${username}/tickets`;
  let docsUrl = "https://docs.platform.sh";
  const logoutUrl = "/-/logout";
  const organizationSettingsUrl = `/${organizationName}/-/settings`;
  const organizationUsersUrl = `/${organizationName}/-/settings/users`;
  const organizationBillingUrl = `/${organizationName}/-/billing`;
  const organizationBillingPlanUrl = `/${organizationName}/-/billing/plan`;

  if (process.env.ACCOUNT_PAGES_DISABLED) {
    profileUrl = `${accountUrl}/user`;
    billingUrl = `${accountUrl}/user/orders`;
    supportUrl = `${accountUrl}/support`;
  }

  if (process.env.CUSTOM_BILLING_URL) {
    billingUrl = process.env.CUSTOM_BILLING_URL;
  }

  if (process.env.CUSTOM_SUPPORT_URL) {
    supportUrl = process.env.CUSTOM_SUPPORT_URL;
  }

  if (process.env.CUSTOM_DOCS_URL) {
    docsUrl = process.env.CUSTOM_DOCS_URL;
  }

  return {
    profileUrl,
    billingUrl,
    organizationSettingsUrl,
    organizationBillingUrl,
    docsUrl,
    supportUrl,
    logoutUrl,
    organizationBillingPlanUrl,
    organizationUsersUrl
  };
};

export const getSubscriptionEditUrl = ({ subscription, project } = {}) => {
  let hasPermission = false;

  if (process.env.ENABLE_ORGANIZATION) {
    hasPermission = subscription?.hasLink && subscription.hasLink("update");
  } else {
    hasPermission =
      (project?.hasPermission &&
        project.hasPermission("#subscription_change")) ||
      !!subscription;
  }

  if (!hasPermission) return false;

  if (process.env.CUSTOM_SUBSCRIPTION_UPGRADE_URL) {
    return {
      url: interpolateURL(process.env.CUSTOM_SUBSCRIPTION_UPGRADE_URL, {
        projectId: project?.id
      }),
      external: true
    };
  }

  if (process.env.ENABLE_ORGANIZATION) {
    const url = subscription?.getLink("plan_uri", false);
    if (!url) return false;
    return {
      url,
      external: false
    };
  }

  if (!project?.plan_uri) return false;
  return {
    url: project.plan_uri,
    external: false
  };
};

/**
 * Check if input matches domain supplied
 * @param {*} domain reference domain to check
 * @param {*} input input containing domain
 * @returns boolean
 */
export const domainMatches = (domain, input) =>
  new RegExp(`^(http[s]?://)?${domain}(/.*)?$`, "gi").test(input);

/**
 * Downloads a data as a file on the clients device
 * @param {*} data the data to download
 * @param {*} type Object expressing the mime type of the file
 * @param {*} downloadName string name by which the download should be save as
 */
export const downloadBlob = (data, type, downloadName) => {
  const blob = new Blob([data], type, downloadName);
  if (navigator.msSaveOrOpenBlob) {
    navigator.msSaveOrOpenBlob(blob, downloadName);
  } else {
    const a = document.createElement("a");
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = downloadName;
    a.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    });
  }
};

export const ellipsis = (text = "", maxLength = 10) => {
  return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
};
