import styled from "styled-components";

import Btn from "UI/Button";

export const Button = styled(Btn)`
  svg path {
    fill: ${({ variant }) => `var(--button-${variant}-foreground, #000)`};
  }
  svg :first-child {
    margin-right: 0 !important;
  }
  display: flex;
  gap: 8px;
`;

export const Text = styled.span`
  position: relative;
  span {
    margin: 0;
  }
`;

export const Copied = styled.span`
  white-space: nowrap;
  position: absolute;
  top: 0;
  left: 0;
`;

export const Copy = styled.span`
  visibility: ${({ copied }) => (copied ? "hidden" : "visible")};
  text-transform: capitalize;
`;
