import React, { useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import { breakpoints } from "Libs/theme";
import NavigationMenu from "Containers/NavBar/NavigationMenu";
import ActionButton from "UI/ActionButton";
import BackupIcon from "Icons/BackupIcon";
import BackupModal from "../Environment/BackupModal";
import FloatBox from "Containers/NavBar/FloatBox";

// this controls when to show the text in the action button
// for this to work the text must be wrapped in a <span> tag
const showCompleteButtonMQ = `(min-width: ${breakpoints[1]})`;

const BackupNavBar = ({
  project,
  environment,
  organizationId,
  projectId,
  environmentId,
  ...props
}) => {
  const [openModal, setOpenModal] = useState(false);

  const intl = useIntl();

  return (
    <FloatBox>
      <NavigationMenu
        project={project}
        environment={environment}
        environmentId={environmentId}
        organizationId={organizationId}
        projectId={projectId}
        {...props}
      />

      <ActionButton
        onClick={() => setOpenModal(true)}
        aria-label={intl.formatMessage({ id: "backup" })}
        expandWhen={showCompleteButtonMQ}
      >
        <BackupIcon /> <span>{intl.formatMessage({ id: "backup" })}</span>
      </ActionButton>
      <BackupModal isOpen={openModal} closeModal={() => setOpenModal(false)} />
    </FloatBox>
  );
};

BackupNavBar.propTypes = {
  project: PropTypes.object,
  environment: PropTypes.object,
  push: PropTypes.func,
  organizationId: PropTypes.string,
  projectId: PropTypes.string,
  environmentId: PropTypes.string,
  currentPathName: PropTypes.string
};

export default BackupNavBar;
