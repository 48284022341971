import ChevronIcon from "Components/icons/ChevronIcon";
import useMediaQuery from "Hooks/useMediaQuery";
import PlatformIconLg from "Icons/PlatformIconLg";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { organizationsMemberOrOwnerOfSelector } from "Reducers/organization";
import VendorLogo from "../../Logo";
import Close from "./Close";
import OrganizationMobileMenu from "./OrganizationMobileMenu";
import * as S from "./styles";

const MobileMenu = ({ user, urls }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSwichOrganization, setIsSwitchOrganization] = useState(false);
  const match = useMediaQuery("(max-width: 764px)");
  const { organizationId } = useParams();

  const organizations = useSelector(organizationsMemberOrOwnerOfSelector);
  const organizationsArray = organizations?.valueSeq();

  const selectedOrganization = organizations.find(
    o => o.name === organizationId
  );

  useEffect(() => {
    document.querySelector("#app").style.overflow =
      match && isOpen ? "hidden" : null;
  }, [isOpen, match]);

  useEffect(() => {
    if (!match) {
      setIsOpen(false);
    }
  }, [match]);

  return (
    match && (
      <S.Layout>
        <Close
          isOpen={isOpen}
          onClick={() => {
            setIsOpen(!isOpen);
            document.activeElement.blur();
          }}
        />
        {isOpen && (
          <S.FloatingContainer isOpen={isOpen}>
            {isSwichOrganization ? (
              <OrganizationMobileMenu
                isSwichOrganization={isSwichOrganization}
                selected={selectedOrganization}
                organizations={organizationsArray}
                onBack={() => setIsSwitchOrganization(!isSwichOrganization)}
              />
            ) : (
              <>
                <S.FloatingLogo>
                  {process.env.VENDOR_URL ? <VendorLogo /> : <PlatformIconLg />}
                </S.FloatingLogo>

                <S.UserMenu>
                  {organizationsArray?.size ? (
                    <S.List>
                      <S.OrganizationSwitcher>
                        <div className="organization_switch_title">
                          <FormattedMessage id="menu.organization" />
                        </div>

                        <S.OrganizationContentSwitcherContent
                          onClick={() =>
                            setIsSwitchOrganization(!isSwichOrganization)
                          }
                        >
                          <S.OrganizationName>
                            <FormattedMessage
                              id={
                                selectedOrganization?.label ||
                                "menu.select_organization"
                              }
                            />
                          </S.OrganizationName>
                          <ChevronIcon className="organization_switch_name_icon" />
                        </S.OrganizationContentSwitcherContent>
                      </S.OrganizationSwitcher>
                    </S.List>
                  ) : null}

                  <S.List>
                    <S.Link onClick={() => setIsOpen(false)} url="/">
                      <FormattedMessage id="menu.projects" />
                    </S.Link>
                  </S.List>

                  {selectedOrganization?.name && (
                    <S.List>
                      <S.Link
                        onClick={() => setIsOpen(false)}
                        url={urls.organizationSettingsUrl}
                      >
                        <FormattedMessage id="menu.settings" />
                      </S.Link>
                    </S.List>
                  )}

                  <S.List>
                    <S.Link
                      onClick={() => setIsOpen(false)}
                      url={urls.billingUrl}
                    >
                      <FormattedMessage id="menu.billing" />
                    </S.Link>
                  </S.List>
                  <S.List>
                    <S.Link
                      onClick={() => setIsOpen(false)}
                      url={urls.supportUrl}
                    >
                      <FormattedMessage id="menu.support" />
                    </S.Link>
                  </S.List>
                  <S.List>
                    <S.Link
                      onClick={() => setIsOpen(false)}
                      url={urls.profileUrl}
                    >
                      <FormattedMessage id="menu.my_profile" />
                    </S.Link>
                  </S.List>
                  <S.List role="button" tabIndex="0">
                    <S.Link
                      url="#"
                      onClick={event => {
                        setImmediate(() =>
                          document.querySelector("#whats-new").click()
                        );
                        event.preventDefault();
                      }}
                    >
                      <FormattedMessage id="announcements.whatsnew" />
                    </S.Link>
                  </S.List>

                  <S.List>
                    <S.Link
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => setIsOpen(false)}
                      url={urls.docsUrl}
                    >
                      <FormattedMessage id="menu.documentation" />
                    </S.Link>
                  </S.List>
                  <S.List>
                    <S.Link
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => setIsOpen(false)}
                      url={urls.logoutUrl}
                    >
                      <FormattedMessage id="logout" />
                    </S.Link>
                  </S.List>
                  <S.List>
                    <S.ProjectButton
                      onClick={() => setIsOpen(false)}
                      linkType="nav"
                      user={user}
                    />
                  </S.List>
                  <S.List>
                    <S.UserInfo>
                      <S.UserProfile
                        src={user?.picture}
                        alt={user?.display_name}
                      />
                      <S.UserName>{user?.display_name}</S.UserName>
                    </S.UserInfo>
                  </S.List>
                </S.UserMenu>
              </>
            )}
          </S.FloatingContainer>
        )}
      </S.Layout>
    )
  );
};

MobileMenu.propTypes = {
  breadcrumbs: PropTypes.node,
  user: PropTypes.object,
  urls: PropTypes.object
};

export default MobileMenu;
