import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import styled, { withTheme } from "styled-components";
import { LiveMessage } from "react-aria-live";

import { css, SECTION, getCSSVarString, MODAL, getColor } from "Libs/themes";

import ModalTitleBar from "./TitleBar";
import CopyButton from "../CopyButton";
import ButtonWrapper from "../ButtonWrapper";

const ModalBody = styled.div`
  width: 100%;
  box-sizing: border-box;
  color:var(--section-night-color,var(--section-night,var(--night)));
  &.fixed-header {
    padding-top: 50px;
    &.with-copy-link {
      padding-top: 135px;
    }
  }
`;

class ModalWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.modalClassOverride = this.modalClassOverride.bind(this);
    this.overlayClassOverride = this.overlayClassOverride.bind(this);

    this.state = {
      modalCustomClass: "modal-after-open",
      overlayCustomClass: "overlay-after-open"
    };
  }

  modalClassOverride(modalClass) {
    if (modalClass) {
      this.setState({
        modalCustomClass: modalClass + " " + this.state.modalCustomClass
      });
    }
    return this.state.modalCustomClass;
  }

  overlayClassOverride(overlayClass) {
    if (overlayClass) {
      this.setState({
        overlayCustomClass: overlayClass + " " + this.state.overlayCustomClass
      });
    }
    return this.state.overlayCustomClass;
  }

  render() {
    const {
      id,
      title,
      closeModal,
      isOpen,
      fixed = false,
      copyText = "",
      modalClass = "",
      overlayClass = "",
      onRequestClose,
      shouldCloseOnOverlayClick,
      announceTitle,
      zIndex,
      backgroundImage,
      backgroundSize,
      backgroundRepeat,
      closeStyle,
      noCloseButton,
      overflowX = "auto",
      overflowY = "auto"
    } = this.props;

    let completeModalClasses = "modal-after-open";
    modalClass ? (completeModalClasses += " " + modalClass) : "";

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose ? onRequestClose : closeModal}
        contentLabel="Modal opened"
        className={{
          base: "modal",
          afterOpen: completeModalClasses,
          beforeClose: "modal-before-close"
        }}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        overlayClassName={{
          base: "overlay",
          afterOpen: overlayClass
            ? overlayClass + " overlay-after-open"
            : "overlay-after-open",
          beforeClose: "overlay-before-close"
        }}
        role="dialog"
        aria={{
          labelledby: id,
          describedby: "modal-body",
          modal: "true"
        }}
        style={{
          overlay: {
            backgroundColor: `${getColor(
              MODAL,
              "dark-shade-overlay",
              "background-color"
            )}90`,
            zIndex,
            overflowX: "hidden"
          },
          content: {
            background:"var(--modal-snow-background-color,var(--modal-snow,var(--snow)))",
            backgroundImage,
            backgroundSize,
            backgroundRepeat,
            overflowX,
            overflowY
          }
        }}
        ariaHideApp={false}
      >
        {announceTitle ? (
          <LiveMessage message={announceTitle} aria-live="polite" />
        ) : (
          <div>
            {title && <LiveMessage message={title} aria-live="polite" />}
          </div>
        )}
        <ModalTitleBar
          id={id}
          title={title}
          closeModal={closeModal}
          fixed={fixed}
          copyText={copyText}
          closeStyle={closeStyle}
          noCloseButton={noCloseButton}
        />
        <ModalBody
          id="modal-body"
          className={`${fixed ? " fixed-header" : ""}${
            copyText ? " with-copy-link" : ""
          }`}
        >
          {copyText ? (
            <React.Fragment>
              {this.props.children}
              <ButtonWrapper className="modal-buttons">
                {copyText.length && <CopyButton text={copyText} />}
              </ButtonWrapper>
            </React.Fragment>
          ) : (
            this.props.children
          )}
        </ModalBody>
      </Modal>
    );
  }
}

ModalWrapper.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  shouldCloseOnOverlayClick: PropTypes.bool,
  fixed: PropTypes.bool,
  copyText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  id: PropTypes.string,
  modalClass: PropTypes.string,
  overlayClass: PropTypes.string,
  overflowX: PropTypes.string,
  overflowY: PropTypes.string,
  onRequestClose: PropTypes.func,
  children: PropTypes.node,
  backgroundImage: PropTypes.string,
  backgroundRepeat: PropTypes.string,
  announceTitle: PropTypes.string,
  theme: PropTypes.object,
  zIndex: PropTypes.number,
  backgroundSize: PropTypes.string,
  closeStyle: PropTypes.string,
  noCloseButton: PropTypes.bool
};

export default withTheme(ModalWrapper);
