import React from "react";
import styled, { css as styledCSS, withTheme } from "styled-components";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { css, LINK, getCSSVarString, ICON } from "Libs/themes";

import PlusIcon from "Icons/PlusIcon";

// eslint-disable-next-line no-unused-vars
const LinkWrapper = styled(({ to, text, blank, id, ...props }) => (
  <span {...props} />
))`
  display: inline-block;
  ${props =>
    props.variant === "mobile" &&
    styledCSS`
      @media (max-width: 768px) {
        width: 100%;
        text-align: center;
      }
    `};

  a {
    background-color:var(--link-skye-background-color,var(--link-skye,var(--skye)));
    color:var(--link-snow-color,var(--link-snow,var(--snow)));
    border: none;
    border-radius: 2px;
    padding: 0 16px;
    height: 30px;
    line-height: 30px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    display: inline-block;
    text-decoration: none;
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;

    ${props =>
      props.variant === "mobile" &&
      styledCSS`
        @media (max-width: 768px) {
          height: 40px;
          line-height: 40px;
          padding: 0;
          width: 100%;
        }
      `};

    &:first-letter {
      text-transform: uppercase;
    }
    &:hover {
      background-color:var(--link-skye-dark-background-color,var(--link-skye-dark,var(--skye-dark)));
    }
    &:focus {
      border: 1px solid var(--link-skye-dark-border-color,var(--link-skye-dark,var(--skye-dark)));
      margin: -1px;
      line-height: ${props => (props.variant === "mobile" ? "40px" : "30px")};
      box-shadow: 0 0 2px
        var(--link-skye-dark-box-shadow-color,var(--link-skye-dark,var(--skye-dark)));
      outline: none;
    }
    &:active {
      margin: 0;
      box-shadow: none;
      border: none;
      background-color:var(--link-skye-dark-background-color,var(--link-skye-dark,var(--skye-dark)));
    }
    &.secondary {
      background: transparent;
      color:var(--link-dark-tint-color,var(--link-dark-tint,var(--dark-tint)));
      &:hover {
        background-color: #ededed;
      }
      &:focus {
        margin-left: inherit;
      }
      &:active {
        background-color: #ededed;
      }
    }
    &.outline {
      background: transparent;
      color:var(--link-skye-color,var(--link-skye,var(--skye)));
      border: 1px solid
        var(--link-periwinkle-grey-border-color,var(--link-periwinkle-grey,var(--periwinkle-grey)));
      margin: -1px;
      line-height: 30px;
      &:hover {
        background-color:var(--link-skye-dark-background-color,var(--link-skye-dark,var(--skye-dark)));
        color:var(--link-snow-color,var(--link-snow,var(--snow)));
        border: none;
        margin: 0;
      }
      &:focus {
        border: 1px solid var(--link-skye-dark-border-color,var(--link-skye-dark,var(--skye-dark)));
        margin: -1px;
        line-height: 30px;
        box-shadow: 0 0 2px
          var(--link-skye-dark-box-shadow-color,var(--link-skye-dark,var(--skye-dark)));
        outline: none;
      }
      &:active {
        margin: 0;
        box-shadow: none;
        border: none;
        background-color:var(--link-skye-dark-background-color,var(--link-skye-dark,var(--skye-dark)));
      }
    }
    &:disabled {
      opacity: 0.8;
      cursor: not-allowed;
    }
    &.add-link {
      display: flex;
      align-items: center;
      padding: 0px 16px 0 8px;
      white-space: nowrap;
      span.text {
        padding-left: 4px;
        display: inline-block;
        &:first-letter {
          text-transform: capitalize;
        }
      }
      span.icon {
        width: 24px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        height: 30px;
        svg {
          width: 10px;
          height: auto;
        }
      }
      &:focus {
        height: 30px;
      }
    }
  }
`;

class ButtonLink extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      to,
      from,
      onClick,
      text,
      primary = false,
      addLink = false,
      external = false,
      blank = true,
      mailLink = false,
      className,
      id,
      variant
    } = this.props;

    return (
      <LinkWrapper variant={variant} className="link-button">
        {external || mailLink ? (
          <a
            id={id}
            className={`button${addLink ? " add-link" : ""}${
              className ? " " + className : ""
            }`}
            href={to}
            role="button"
            tabIndex="0"
            target={mailLink || blank ? "_blank" : ""}
            rel={mailLink || blank ? "noopener noreferrer" : ""}
          >
            {addLink ? (
              <React.Fragment>
                <span className="icon">
                  <PlusIcon aria-hidden="true" />
                </span>
                <span className="text">{text}</span>
              </React.Fragment>
            ) : (
              text
            )}
          </a>
        ) : (
          <Link
            id={id}
            className={`button${primary ? " primary" : ""}${
              addLink ? " add-link" : ""
            }${className ? " " + className : ""}`}
            to={from ? { pathname: to, state: { from } } : to}
            onClick={
              onClick
                ? e => {
                    e.preventDefault();
                    onClick(e);
                  }
                : () => {}
            }
            role="button"
            tabIndex="0"
            href={to}
          >
            {addLink ? (
              <React.Fragment>
                <span className="icon">
                  <PlusIcon
                    color={"var(--icon-snow-fill,var(--icon-snow,var(--snow)))"}
                    aria-hidden="true"
                  />
                </span>
                <span className="text">{text}</span>
              </React.Fragment>
            ) : (
              text
            )}
          </Link>
        )}
      </LinkWrapper>
    );
  }
}

ButtonLink.propTypes = {
  to: PropTypes.string,
  from: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  primary: PropTypes.bool,
  addLink: PropTypes.bool,
  external: PropTypes.bool,
  blank: PropTypes.bool,
  mailLink: PropTypes.bool,
  className: PropTypes.string,
  id: PropTypes.string,
  variant: PropTypes.string,
  theme: PropTypes.object
};

export default withTheme(ButtonLink);
