import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Map } from "immutable";
import { push as reactRouterReduxPush } from "connected-react-router";

import { push as pushAction } from "Reducers/app";
import {
  loadEnvironment,
  loadEnvironments,
  environmentSelector,
  environmentsSelector,
  environmentTreeSelector
} from "Reducers/environment";
import { loadSubscription, loadSubscriptions } from "Reducers/subscription";
import {
  loadSubscription as loadOrganizationSubscription,
  loadSubscriptions as loadOrganizationSubscriptions
} from "Reducers/organization/subscription";

import { toggleProjectWizard, setForceOpen } from "Reducers/project/wizard";
import { search } from "Reducers/app/search";

import OrganizationsSwitcher from "./OrganizationsSwitcher";

import {
  NavBarLayout,
  NavBarLeftLayout,
  NavBarRightLayout,
  PageHeader,
  OrganizationDivider
} from "./styles";
import Logo from "./Logo";
import UserWidget from "./UserWidget";
import UserMenu from "./UserMenu";
import MaxWidthContainer from "Components/MaxWidthContainer";
import Breadcrumbs from "./Breadcrumbs";
import BannerManager from "Containers/Banner";
import SearchBar from "./SearchBar/SearchBar";
import ProjectNavBar from "./Project";
import EnvironmentNavBar from "./Environment";
import BackupNavBar from "./Backup";
import InsideNavBar from "./InsideNavBar";

import {
  ENVIRONMENT_ID_FIELD,
  BILLING_PATH_MATCH,
  SETTINGS_PATH_MATCH,
  SUPPORT_PATH_MATCH
} from "Constants/constants";
import { isProjectOwner } from "Libs/utils";

let announcements = null;

if (process.env.ENABLE_ANNOUNCEMENTS) {
  const AnnouncementDropdown = React.lazy(() =>
    import("Containers/announcements/Dropdown")
  );

  announcements = <AnnouncementDropdown />;
}

class NavBar extends React.Component {
  async componentDidMount() {
    document.addEventListener("click", this.handleClickOutside, false);

    // If NavBar loads, make sure these are removed.
    const preLoadingContainer = document.getElementById("pre-loading");
    const fallbackContainer = document.getElementById("fallback");

    if (preLoadingContainer) {
      preLoadingContainer.parentNode.removeChild(preLoadingContainer);
    }

    if (fallbackContainer) {
      fallbackContainer.parentNode.removeChild(fallbackContainer);
    }

    // If we are on a project, the project reducer will take care of the organization loading
    if (
      process.env.ENABLE_ORGANIZATION &&
      this.props.organizationId &&
      !this.props.projectId
    ) {
      this.props.loadOrganization(this.props.organizationId);
    }

    if (this.props.projectId && this.props.organizationId) {
      this.props.loadEnvironments(
        this.props.projectId,
        this.props.organizationId
      );
    }

    const subscriptionId = this.props.project?.getSubscriptionId();
    if (subscriptionId) {
      this.props.loadSubscription(
        this.props.organizationId,
        this.props.projectId,
        subscriptionId
      );
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // If we are on a project, the project reducer will take care of the organization loading
    if (
      process.env.ENABLE_ORGANIZATION &&
      nextProps.organizationId !== this.props.organizationId &&
      !nextProps.projectId
    ) {
      this.props.loadOrganization(nextProps.organizationId);
    }

    if (
      nextProps.project &&
      this.props.project &&
      nextProps.project?.id !== this.props.project?.id
    ) {
      const subscriptionId = nextProps.project?.getSubscriptionId();
      this.props.loadSubscription(
        nextProps.organizationId,
        nextProps.project.id,
        subscriptionId
      );
    }
    if (nextProps.projectId && nextProps.projectId !== this.props.projectId) {
      this.props.loadEnvironments(
        nextProps.projectId,
        nextProps.organizationId
      );
    }
  }

  componentDidUpdate(prevProps) {
    const projId = this.props.project ? this.props.project.id : null;
    const prevProjId = prevProps.project ? prevProps.project.id : null;
    const shouldLoadSubscription =
      projId !== prevProjId &&
      this.props.subscription === prevProps.subscription;
    if (shouldLoadSubscription && this.props.project) {
      const subscriptionId = this.props.project?.getSubscriptionId();
      this.props.loadSubscription(
        this.props.organizationId,
        this.props.project.id,
        subscriptionId
      );
    }
  }

  getEnvironmentPathList = () => {
    const { environmentId, environments, environmentTree } = this.props;
    if (!environmentTree) return [];
    let currentEnvironmentPath =
      environmentTree.get("path")?.valueSeq().toArray() || [];
    currentEnvironmentPath.push(environmentId);
    if (!environments.size) return [];

    return currentEnvironmentPath.reduce((accu, elt) => {
      const environment = environments?.find(env => env.id === elt);
      if (environment) {
        accu.push({
          id: encodeURIComponent(environment[ENVIRONMENT_ID_FIELD]),
          name: environment.name,
          title: environment.title
        });
      }
      return accu;
    }, []);
  };

  toggleTooltips(index) {
    this.setState(prevState => ({
      index: prevState.index === index ? -1 : index
    }));
  }

  render() {
    const {
      projectId,
      organizationId,
      username,
      pushAction,
      push,
      projects,
      isLoadingProjectList,
      project,
      environmentId,
      environment,
      currentPathName,
      subscription,
      toggleProjectWizard,
      organizations,
      me,
      setSearch,
      query
    } = this.props;

    const user = me?.toJS();

    const projectOwner = isLoadingProjectList
      ? false
      : projects.some(project =>
          isProjectOwner(project, user, organizations?.toJS())
        );

    const organization = organizations?.get(organizationId);
    let outsideNavbar = false;
    let insideNavbar = false;

    const onSearchChange = value => {
      value ? setSearch(value) : setSearch("");
    };

    const isProjectSettingsPage = currentPathName.startsWith(
      // TODO:SETTINGS: change ss to settings
      `/${organizationId}/${projectId}/-/ss`
    );

    const isEnvironmentSettingsPage = currentPathName.startsWith(
      // TODO:SETTINGS: change ss to settings
      `/${organizationId}/${projectId}/${environmentId}/ss`
    );

    let classnames = [];
    if (
      currentPathName.includes("/create-project") ||
      currentPathName.includes("/billing")
    )
      classnames.push("user-menu-fix");

    if (
      SUPPORT_PATH_MATCH.test(currentPathName) ||
      BILLING_PATH_MATCH.test(currentPathName) ||
      SETTINGS_PATH_MATCH.test(currentPathName)
    )
      classnames.push("settings");

    if (
      BILLING_PATH_MATCH.test(currentPathName) ||
      SETTINGS_PATH_MATCH.test(currentPathName) ||
      new RegExp(`^/${organizationId}/-/(settings|billing)`).test(
        currentPathName
      )
    ) {
      insideNavbar = (
        <InsideNavBar
          username={username}
          organizationId={organizationId}
          currentPathName={currentPathName}
          organization={organization}
        />
      );
    } else if (
      currentPathName.startsWith(
        `/${organizationId}/${projectId}/-/integrations/setup`
      ) ||
      isProjectSettingsPage ||
      isEnvironmentSettingsPage
    ) {
      outsideNavbar = false;
    } else if (
      decodeURIComponent(currentPathName) ===
      `/${organizationId}/${projectId}/${environmentId}/backups`
    ) {
      outsideNavbar = (
        <BackupNavBar
          push={push}
          pushAction={pushAction}
          organizationId={organizationId}
          projectId={projectId}
          environmentId={environmentId}
          project={project}
          projects={projects}
          environment={environment}
          currentPathName={currentPathName}
        />
      );
    } else if (
      project &&
      (!environment?.id ||
        [
          `/${organizationId}/${projectId}/${environmentId}/settings`,
          `/${organizationId}/${projectId}/${environmentId}/services/routes`,
          `/${organizationId}/${projectId}/${environmentId}/settings/variables`
        ].includes(decodeURIComponent(currentPathName)))
    ) {
      outsideNavbar = (
        <ProjectNavBar
          toggleProjectWizard={toggleProjectWizard}
          subscription={subscription}
          pushAction={pushAction}
          push={push}
          organizationId={organizationId}
          projectId={projectId}
          environmentId={environmentId}
          project={project}
          projects={projects}
          environment={environment}
        />
      );
    } else if (
      environment &&
      environment.id &&
      typeof environment.hasLink === "function"
    ) {
      outsideNavbar = (
        <EnvironmentNavBar
          push={push}
          pushAction={pushAction}
          organizationId={organizationId}
          projectId={projectId}
          environmentId={environmentId}
          project={project}
          projects={projects}
          environment={environment}
          currentPathName={currentPathName}
        />
      );
    } else if (
      !project &&
      (currentPathName === "/" ||
        new RegExp(`^/${organizationId}/{0,1}$`).test(currentPathName))
    ) {
      outsideNavbar = (
        <SearchBar
          currentPathName={currentPathName}
          onSearchChange={onSearchChange}
          organizationId={organizationId}
          organization={organization}
          user={user}
          push={push}
          query={query}
        />
      );
    }

    return (
      <NavBarLayout className={classnames.join(" ")}>
        {!process.env.ENABLE_ORGANIZATION ||
          (process.env.ENABLE_ORGANIZATION && organizationId && (
            <BannerManager
              isProjectOwner={projectOwner}
              user={user}
              organizationId={organizationId}
              projects={projects}
            />
          ))}
        <PageHeader
          id="page-header"
          reduceHeight={isProjectSettingsPage || isEnvironmentSettingsPage}
        >
          <MaxWidthContainer>
            <div className="navigation-bar" role="navigation">
              <NavBarLeftLayout>
                <Logo variant={project ? "withBreadcrumbs" : "standalone"} />

                {process.env.ENABLE_ORGANIZATION && (
                  <OrganizationsSwitcher
                    pushAction={pushAction}
                    organizationId={organizationId}
                  />
                )}

                <OrganizationDivider className="organization-divider">
                  {process.env.ENABLE_ORGANIZATION &&
                    currentPathName !== "/" &&
                    currentPathName !== `/${organizationId}` &&
                    !currentPathName.startsWith("/-/users") &&
                    "|"}
                </OrganizationDivider>

                <Breadcrumbs
                  currentPath={currentPathName}
                  environmentPath={this.getEnvironmentPathList()}
                  hiddenXs
                  projectName={project?.title}
                />
              </NavBarLeftLayout>
              <NavBarRightLayout>
                {announcements}
                {user && user.data && (
                  <UserMenu
                    breadcrumbs={
                      <Breadcrumbs
                        currentPath={currentPathName}
                        environmentPath={this.getEnvironmentPathList()}
                        projectName={project?.title}
                      />
                    }
                    accountUrl={process.env.ACCOUNTS_URL}
                    user={user}
                    organizations={organizations}
                    organizationId={organizationId}
                  />
                )}
                <UserWidget
                  accountUrl={process.env.ACCOUNTS_URL}
                  source="ui"
                  nameOrAvatar={true}
                  getQueryString={() => ({
                    projectId
                  })}
                />
              </NavBarRightLayout>
            </div>
          </MaxWidthContainer>
          {insideNavbar}
        </PageHeader>
        {outsideNavbar}
      </NavBarLayout>
    );
  }
}

const mapStateToProps = (state, props) => {
  const me = state.app?.get("me", new Map());
  const project = state.project?.getIn([
    "data",
    props.organizationId,
    props.projectId
  ]);
  return {
    query: state.search.get("query", ""),
    projects: state.project
      .getIn(["list"], new Map())
      .keySeq()
      .reduce((projects, key) => {
        state.project.getIn(["list", key], new Map()).forEach(project => {
          projects = projects.mergeDeep(projects.set(project.id, project));
        });
        return projects;
      }, new Map()),
    project,
    isLoadingProjectList: state.project?.get("loadingList"),
    environment: environmentSelector(state, {
      organizationId: props.organizationId,
      projectId: props.projectId,
      environmentId: props.environmentId
    }),
    environments: environmentsSelector(state, {
      organizationId: props.organizationId,
      projectId: props.projectId
    }),
    environmentTree: environmentTreeSelector(state, {
      organizationId: props.organizationId,
      projectId: props.projectId,
      environmentId: props.environmentId
    }),
    subscription: process.env.ENABLE_ORGANIZATION
      ? state?.organizationSubscription?.getIn([
          "data",
          props.organizationId,
          project?.subscription_id
        ])
      : state?.subscription?.getIn([
          "data",
          props.organizationId,
          props.projectId
        ]),
    organizations: state.organization.get("orgByDescriptionField"),
    deletedSubscription:
      state.subscription && state.subscription.get("deleted"),
    announcementsError: state.announcements.get("error"),
    me
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  pushAction: (path, args) => dispatch(pushAction(path, args)),
  loadEnvironment: (
    currentProjectOrganization = props.organizationId,
    currentEnvironmentId = props.environmentId
  ) =>
    dispatch(
      loadEnvironment({
        environmentId: currentEnvironmentId,
        projectId: props.projectId,
        organizationId: currentProjectOrganization
      })
    ),
  setSearch: value => dispatch(search(value)),
  loadEnvironments: (projectId, organizationId) =>
    dispatch(loadEnvironments({ projectId, organizationId })),
  loadOrganization: organizationId => {
    import("Reducers/organization").then(organizationReducer =>
      dispatch(organizationReducer.loadOrganization(organizationId))
    );
  },
  toggleProjectWizard: () => {
    const { currentPathName, projectId, organizationId } = props;

    if (currentPathName.includes(`/${organizationId}/${projectId}/settings`)) {
      dispatch(setForceOpen(true));
      dispatch(reactRouterReduxPush(`/${organizationId}/${projectId}`));
    } else {
      dispatch(toggleProjectWizard({ organizationId, projectId }));
    }
  },
  loadSubscriptions: user =>
    process.env.ENABLE_ORGANIZATION
      ? dispatch(
          loadOrganizationSubscriptions({
            organizationId: props.organizationId
          })
        )
      : dispatch(loadSubscriptions({ organizationId: user?.username })),
  loadSubscription: (organizationId, projectId, id) =>
    dispatch(
      (process.env.ENABLE_ORGANIZATION
        ? loadOrganizationSubscription
        : loadSubscription)({ organizationId, projectId, id })
    )
});

NavBar.propTypes = {
  announcementsError: PropTypes.string,
  currentPathName: PropTypes.string,
  currentProjectOrganization: PropTypes.string,
  deletedSubscription: PropTypes.object,
  environment: PropTypes.object,
  environmentId: PropTypes.string,
  environments: PropTypes.object,
  environmentTree: PropTypes.object,
  isLoadingProjectList: PropTypes.bool,
  loadEnvironment: PropTypes.func,
  loadOrganization: PropTypes.func,
  loadEnvironments: PropTypes.func,
  loadProjects: PropTypes.func,
  loadSubscription: PropTypes.func,
  loadSubscriptions: PropTypes.func,
  me: PropTypes.object,
  onSearchChange: PropTypes.func,
  query: PropTypes.string,
  organizationId: PropTypes.string,
  project: PropTypes.object,
  projectId: PropTypes.string,
  username: PropTypes.string,
  projects: PropTypes.object,
  push: PropTypes.func,
  pushAction: PropTypes.func,
  setSearch: PropTypes.func,
  subscription: PropTypes.object,
  organizations: PropTypes.object,
  toggleProjectWizard: PropTypes.func,
  updateUserProfile: PropTypes.func,
  user: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
