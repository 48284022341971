import { fromJS, Map } from "immutable";

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getOrganizationId } from "Libs/utils";

export const getOrganizationRegions = createAsyncThunk(
  "app/organization/region",
  async ({ organizationId }, { getState }) => {
    const platformLib = await import("Libs/platform");
    const client = platformLib.default;

    const result = await client.getOrganizationRegions(
      getOrganizationId(getState, organizationId)
    );

    const allRegions = result?.items;

    const availableRegions = allRegions.filter(
      region => region.available == true // Double equals because ome available keys are strings, and some aren't.
    );

    const byUrl = allRegions.reduce((urls, region) => {
      const url = region.endpoint.replace("https://", "").replace("/api", "");

      urls[url] = region.project_label || "";
      return urls;
    }, {});

    return {
      result,
      byUrl,
      availableRegions
    };
  }
);

const setError = (state, action) =>
  state.set("errors", action.error.message).set("loading", false);

const organizationRegion = createSlice({
  name: "organizationRegion",
  initialState: Map({ data: new Map(), loading: "idle" }),
  reducers: {},
  extraReducers: {
    [getOrganizationRegions.pending]: state => state.set("loading", true),
    [getOrganizationRegions.fulfilled]: (state, action) => {
      const { organizationId } = action.meta.arg;
      const availableRegions = action.payload.availableRegions;

      return state
        .setIn(
          ["data", organizationId],
          fromJS(
            availableRegions.reduce((acc, region) => {
              acc[region.id] = region;
              return acc;
            }, {})
          )
        )
        .setIn(
          ["byRegion", organizationId],
          fromJS(
            availableRegions.reduce((accumulator, current) => {
              if (!accumulator[current.zone]) {
                accumulator[current.zone] = [current];
                return accumulator;
              }

              accumulator[current.zone].push(current);
              return accumulator;
            }, {})
          )
        )
        .setIn(["byUrl", organizationId], fromJS(action.payload.byUrl))
        .setIn(["errors", organizationId], false)
        .set("loading", false);
    },
    [getOrganizationRegions.rejected]: (state, action) =>
      setError(state, action)
  }
});

export default organizationRegion.reducer;

export const organizationRegionSelector = (state, props) =>
  state.organizationRegion.getIn(["data", props.organizationId]);
