import { fromJS, Map, List } from "immutable";
import { push as reactRouterReduxPush } from "connected-react-router";

import { loadLastVisitedProjects, loadProjects } from "Reducers/project";
import { getPlans } from "Reducers/plan";
import { loadCurrentUserProfile } from "Reducers/profile";
import {
  LOAD_ORGANIZATIONS_SUCCESS,
  LOAD_REF_ORGANIZATIONS_SUCCESS
} from "Reducers/organization";
import { getOrganizationRegions } from "Reducers/organization/region";
import { ORGANIZATION_ID_FIELD } from "Constants/constants";

import { loadSubscriptions } from "../subscription";

import { getEnvironmentDescriptionId } from "Libs/utils";
import parse from "Libs/urlParser";

import logger from "Libs/logger";

export const INIT_START = "app/init_start";
export const INIT_SUCCESS = "app/init_success";
const INIT_FAILURE = "app/init_failure";

const GET_ME_START = "app/get_me_start";
export const GET_ME_SUCCESS = "app/get_me_success";
const GET_ME_FAILURE = "app/get_me_failure";

export const init = () => {
  return async dispatch => {
    dispatch({ type: INIT_START });
    try {
      dispatch(getMe());
      dispatch(getPlans());

      dispatch({
        type: INIT_SUCCESS
      });
    } catch (err) {
      logger(err, {
        action: "init"
      });
      dispatch({ type: INIT_FAILURE, error: true, payload: err });
    }
  };
};

export const getMe = (reload = false) => {
  return async dispatch => {
    dispatch({ type: GET_ME_START });

    try {
      // Load the current user
      const platformLib = await import("Libs/platform");
      const client = platformLib.default;

      const me = await client.getAccountInfo(true);

      if (!reload) {
        if (!process.env.ENABLE_ORGANIZATION) {
          // Load all the users subscriptions at first load to avoid race condition with loadSubscription
          dispatch(loadSubscriptions({ organizationId: me?.username }));
        }

        if (process.env.ENABLE_ORGANIZATION) {
          try {
            const res = await Promise.all([
              client.getOrganizations({
                userId: me?.uuid
              }),
              me.getOrganizations()
            ]);

            dispatch({
              type: LOAD_REF_ORGANIZATIONS_SUCCESS,
              payload: res[1]
            });

            dispatch({
              type: LOAD_ORGANIZATIONS_SUCCESS,
              payload: res[0]
            });

            // Load regions for all organizations
            res[0].forEach(o => {
              dispatch(getOrganizationRegions({ organizationId: o?.name }));
            });
          } catch (err) {
            logger(err, {
              action: "LOAD_REF_ORGANIZATIONS"
            });
          }
        }

        dispatch(loadLastVisitedProjects());
      }

      dispatch({
        type: GET_ME_SUCCESS,
        payload: {
          me: { ...me }
        }
      });

      dispatch(loadCurrentUserProfile());
      dispatch(loadProjects());
    } catch (err) {
      logger(err, {
        action: "GET_ME_START"
      });
      dispatch({ type: GET_ME_FAILURE, error: true, payload: err });
    }
  };
};

export const push = (path, args) => async (dispatch, getState) => {
  const descriptionArgs = { ...args };

  if (args.organizationId) {
    const organization = getState().organization.getIn(
      "data",
      args.organizationId,
      {}
    );

    descriptionArgs.organizationId = organization[ORGANIZATION_ID_FIELD];
  }

  if (args.projectId) {
    descriptionArgs.projectId = args.projectId;
  }

  if (args.environmentId) {
    descriptionArgs.environmentId = getEnvironmentDescriptionId(
      getState,
      args.environmentId
    );
  }

  const url = parse(path, descriptionArgs);

  dispatch(reactRouterReduxPush(url));
};

export default function appReducer(state = Map(), action) {
  switch (action.type) {
    case INIT_SUCCESS:
      return state.setIn(
        ["routeHistory", "currentRoute"],
        window.location.pathname
      );
    case GET_ME_SUCCESS:
      return state.set("me", fromJS(action.payload.me));
    case "@@router/LOCATION_CHANGE":
      // This action doesn't contain the previous route so we have to log each
      // route in the store, then move it to a list that contains the history.
      // Then use .toSet().toList() to ensure no duplicates are added to the history.
      return state
        .setIn(
          ["routeHistory", "currentRoute"],
          action.payload.location.pathname
        )
        .setIn(
          ["routeHistory", "previousRoutes"],
          state
            .getIn(["routeHistory", "previousRoutes"], List())
            .unshift(state.getIn(["routeHistory", "currentRoute"], null))
            .toSet()
            .toList()
        );
    default:
      return state;
  }
}

export const meSelector = state => state.app.get("me");
