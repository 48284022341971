import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import { Map } from "immutable";

import useDecodedParams from "Hooks/useDecodedParams";
import useSelectorWithUrlParams from "Hooks/useSelectorWithUrlParams";

import {
  environmentSelector,
  environmentLoadingSelector
} from "Reducers/environment";

import { capitalize } from "Libs/utils";
import ModalWrapper from "Components/Modal";
import Button from "UI/Button";
import ContentPaneLayout from "Components/ContentPaneLayout";
import Loading from "Components/Loading";
import Error from "Components/Error";
import CopyableArea from "Components/CopyableArea";
import ButtonWrapper from "Components/ButtonWrapper";
import Heading4 from "Components/styleguide/Heading4";
import InfoDialog from "Components/InfoDialog";

import { backup } from "Reducers/environment/actions/backup";
import { push } from "Reducers/app";

const BackupModal = ({ isOpen, closeModal }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const location = useLocation();
  const { environmentId, organizationId, projectId } = useDecodedParams();

  const command = `${intl.formatMessage({
    id: "CLI_command_name"
  })} backup:create`;

  const environment = useSelectorWithUrlParams(environmentSelector);
  const isEnvironmentLoading = useSelectorWithUrlParams(
    environmentLoadingSelector
  );
  const isLoading = useSelector(state => state.backup?.get("loading", false));
  const errors = useSelector(state => state.backup?.get("errors", new Map()));

  const handleBackup = e => {
    if (e) e.preventDefault();
    dispatch(backup(organizationId, projectId, environmentId));
    closeModal();
    if (!location.pathname.includes("backups")) {
      push("/:organizationId/:projectId/:environmentId/backups", {
        organizationId,
        projectId,
        environmentId
      });
    }
  };

  return (
    <ModalWrapper
      id="environment-backup-modal"
      shouldCloseOnOverlayClick={true}
      isOpen={isOpen}
      title={intl.formatMessage(
        { id: "backup.title" },
        { environmentName: environment?.name }
      )}
      closeModal={closeModal}
      modalClass="modal-medium modal-environment-action modal-environment-backup"
      announceTitle={intl.formatMessage(
        { id: "backup.title" },
        { environmentName: environment?.name }
      )}
    >
      <ContentPaneLayout className="modal-body">
        {errors && errors.get("message") && (
          <Error>{errors.get("message")}</Error>
        )}
        <p>
          {intl.formatMessage({
            id: "environment.backups.modal.explanation",
            defaultMessage:
              "Creates a complete backup of the environment. It includes all persistent data from all running services (MySQL, SOLR,...) and any files stored on the mounted volumes."
          })}
        </p>
        <Heading4 style={{ marginBottom: 16 }}>
          CLI{" "}
          <InfoDialog
            title="Learn more"
            text={`You can also use this terminal command to create a backup of ${environment?.title}`}
            to={intl.formatMessage({
              id: "links.documentation.getting_started_CLI"
            })}
            linkText="Help"
          />
        </Heading4>
        <CopyableArea
          id="environment-backup-cmd"
          content={command}
          singleLine={true}
        >
          {command}
        </CopyableArea>
        {isEnvironmentLoading || isLoading ? (
          <Loading />
        ) : (
          <ButtonWrapper className="modal-buttons">
            <Button
              id="environment-backup-save-btn"
              type="submit"
              aria-label={intl.formatMessage({ id: "backup.button" })}
              onClick={handleBackup}
            >
              {capitalize(intl.formatMessage({ id: "backup.button" }))}
            </Button>
            <Button
              id="environment-backup-cancel-btn"
              variant="secondary"
              type="button"
              aria-label={intl.formatMessage({ id: "cancel" })}
              onClick={closeModal}
            >
              {capitalize(intl.formatMessage({ id: "cancel" }))}
            </Button>
          </ButtonWrapper>
        )}
      </ContentPaneLayout>
    </ModalWrapper>
  );
};

BackupModal.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func
};

export default BackupModal;
