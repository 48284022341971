import PropTypes from "prop-types";
import React from "react";
import { useIntl } from "react-intl";

import CloseButton from "Components/CloseBtn";
import InputField from "Components/fields/InputField";
import InfoDialog from "Components/InfoDialog";

import { SubHeading } from "../SourceOperation.style";
import * as S from "./Variables.styles";

const VARIABLE_FIELD = {
  NAME: "name",
  VALUE: "value"
};

const OperationVariables = ({ variables, setVariables }) => {
  const formatMessage = useIntl().formatMessage;

  const onVariableChange = (index, field, input) => {
    const { name, value } = variables[index];
    if (field === VARIABLE_FIELD.NAME) {
      variables[index] = { name: input, value };
      setVariables([...variables]);
      return;
    }
    variables[index] = { name, value: input };
    setVariables([...variables]);
  };

  const onRemoveVariable = index => {
    variables.splice(index, 1);
    setVariables([...variables]);
  };

  return (
    <S.VariableWrapper>
      <S.HeaderSection>
        <S.AddVariableHeader>
          <SubHeading>
            {formatMessage({
              id: "override_variables"
            })}
          </SubHeading>
          <InfoDialog
            text={formatMessage({
              id: "source_ops.define_variables_info"
            })}
            to={formatMessage({
              id: "links.documentation.variables.run_and_build_time"
            })}
            linkText={formatMessage({ id: "learnmore" })}
          />
        </S.AddVariableHeader>

        <S.NewVariableWrapper
          role="button"
          variant="secondary"
          className="add-variable-wrapper"
          data-testid="add-variable-wrapper"
          tabIndex={0}
          onClick={event => {
            event.preventDefault();
            setVariables([{ name: "", value: "" }, ...variables]);
          }}
        >
          <span className="plus-icon"> &#43; </span>
          <span className="add-variable">
            {formatMessage({ id: "settings.variables.add.title" })}
          </span>
        </S.NewVariableWrapper>
      </S.HeaderSection>
      {variables.map((input, i) => (
        <S.VariableInput
          className={`operation-variable operation-variable-${i}`}
          data-testid={`operation-variable-${i}`}
          key={`${input}_${i}`}
        >
          <InputField
            id={`variable-name-${i}`}
            label={formatMessage({ id: "source_ops.variable.name" })}
            placeholder={formatMessage({
              id: "source_ops.variable.name.placeholder"
            })}
            className="variable-name"
            value={input.name}
            required={!!input.value}
            onChange={({ target }) =>
              onVariableChange(i, VARIABLE_FIELD.NAME, target.value)
            }
          />
          <InputField
            required={!!input.name}
            id={`variable-value-${i}`}
            label={formatMessage({ id: "source_ops.variable.value" })}
            placeholder={formatMessage({
              id: "source_ops.variable.value.placeholder"
            })}
            className="variable-value"
            value={input.value}
            onChange={({ target }) =>
              onVariableChange(i, VARIABLE_FIELD.VALUE, target.value)
            }
          />
          <CloseButton
            testId={`remove-operation-variable-${i}`}
            onClick={() => onRemoveVariable(i)}
          />
        </S.VariableInput>
      ))}
    </S.VariableWrapper>
  );
};

OperationVariables.propTypes = {
  variables: PropTypes.array,
  setVariables: PropTypes.func
};

export default OperationVariables;
