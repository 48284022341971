import styled, { css as styledCSS } from "styled-components";

import {
  css,
  LINK,
  ICON,
  SECTION,
  getCSSVarString,
  MENU,
  BUTTON
} from "Libs/themes";

import InfoDialog from "Components/InfoDialog";

export const ListBarWrapper = styled.div`
  width: 100%;
  position: relative;
  > a {
    display: inline-block;
    width: 100%;
    cursor: pointer;
    padding: 4px;
    margin: -4px;
    text-decoration: none;
    color: #1a192b;
    box-sizing: border-box;
  }
  .icon {
    &.inactive {
      svg path {
        fill:var(--icon-granite-fill,var(--icon-granite,var(--granite)));
      }
    }
  }
  button {
    text-decoration: none;
    color: #1a192b;
    svg {
      opacity: 0.3;
    }
    &:hover svg,
    &.open svg {
      opacity: 1;
    }
  }
  .moment {
    text-align: right;
    font-size: 13px;
    color:var(--section-slate-color,var(--section-slate,var(--slate)));
  }
  .title {
    font-size: 14px;
    color:var(--section-ebony-color,var(--section-ebony,var(--ebony)));
    @media (min-width: 1130px) {
      font-size: 15px;
    }
  }
  > a:hover,
  > a:focus {
    background-color:var(--link-skye-light-background-color,var(--link-skye-light,var(--skye-light)));
    border-radius: 4px;
    outline: none;
  }

  ${props =>
    props.theme.name === "contrast" &&
    styledCSS`
      > a:focus {
        background: transparent;
        box-shadow: 0 0 0 1px ${getCSSVarString(
          SECTION,
          "skye",
          "box-shadow-color"
        )};
      }
    `};

  .blue {
    color:var(--link-skye-color,var(--link-skye,var(--skye)));
    background: transparent;
  }
  .website-link {
    position: absolute;
    right: 32px;
    top: 0;
    button {
      padding: 4px !important;
      background: transparent;
      border: none;
      width: 32px !important;
      height: 32px;
      box-shadow: none;
      border-radius: 4px;
      box-sizing: border-box;
      .svg-fill {
        fill:var(--link-skye-fill,var(--link-skye,var(--skye)));
      }
      &:hover {
        background: ${getCSSVarString(
          BUTTON,
          "skye",
          "background-color"
        )} !important;
        .svg-fill {
          fill:var(--icon-snow-fill,var(--icon-snow,var(--snow)));
        }
      }
      &:focus {
        border: 1px solid var(--button-skye-border-color,var(--button-skye,var(--skye)));
        box-shadow: 0 0 2px
          var(--button-skye-box-shadow-color,var(--button-skye,var(--skye)));
        outline: none;
        height: 34px;
        width: 34px;
        margin: -1px;
        border-radius: 4px;
      }
      &.open,
      &:active {
        border: none !important;
        box-shadow: none !important;
        height: 32px;
        width: 32px;
        margin: 0;
      }
      svg {
        opacity: 1;
        margin: 0;
        width: 100%;
      }
    }
    &.open-wrapper {
      .tooltip {
        width: 80vw;
        border-radius: 2px;
        box-shadow: var(--menu-box-shadow-box-shadow,var(--menu-box-shadow,var(--box-shadow)))
          var(--menu-ebony-light-box-shadow-color,var(--menu-ebony-light,var(--ebony-light)));
        margin-top: 4px;
        > section {
          max-height: 264px;
          border-radius: 2px;
          box-sizing: border-box;
          padding: 0;
        }
        .rcs-custom-scroll {
          .rcs-inner-container {
            padding: 8px;
            box-sizing: border-box;
          }
          .rcs-inner-handle {
            background-color:var(--section-dark-tint-background-color,var(--section-dark-tint,var(--dark-tint)));
            width: 4px;
            border-radius: 4px;
            opacity: 1;
          }
        }
        .no-scroll {
          padding: 8px;
        }
        .route-link {
          line-height: 32px;
          height: 32px;
          padding: 0 16px;
          margin: 0;
          box-sizing: border-box;
          &:hover {
            background-color:var(--link-skye-light-background-color,var(--link-skye-light,var(--skye-light)));
            border-radius: 4px;
          }
          &:focus {
            outline: none;
            border: 1px solid #1664ff;
            border-radius: 4px;
            box-shadow: 0 0 2px #1664ff;
            margin: -1px;
          }
          &:active {
            background: transparent;
            border: none;
            box-shadow: none;
            margin: 0;
            span {
              color:var(--link-skye-color,var(--link-skye,var(--skye)));
            }
          }
        }
      }
      button {
        border-radius: 4px;
      }
      &:after {
        display: none;
      }
    }
    @media (min-width: 600px) {
      right: 48px;
      &.open-wrapper {
        .tooltip {
          width: 356px;
        }
      }
    }
    @media (min-width: 1280px) {
      right: 188px;
    }
  }
`;

export const WingsDialog = styled(InfoDialog)`
  position: absolute;
  right: 0;
  top: 4px;
  margin: 0;
  svg > path {
    fill:var(--icon-skye-fill,var(--icon-skye,var(--skye)));
  }
  .dialog {
    right: 0;
    left: auto;
    margin-left: 0;
  }
  @media (min-width: 600px) {
    right: 8px;
  }
  @media (min-width: 1280px) {
    right: 132px;
  }
`;

export const ListBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  width: 100%;
  white-space: nowrap;
  position: relative;
  .title,
  .moment,
  .user-name,
  .commit-diff {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &.inactive {
    .title,
    .link,
    .user-name,
    .commit-diff {
      // Tooltip's opacity should not be reduced
      > * > *:not([role="tooltip"]) {
        opacity: 0.8;
      }
    }
  }
  .moment:hover {
    opacity: 1;
  }
`;

export const ListBarRight = styled.div`
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  width: 92px;
  position: absolute;
  right: 0;
  line-height: 32px;
  .link {
    cursor: pointer;
    display: inline-block;
  }
  .moment {
    box-sizing: border-box;
    padding: 0;
    font-weight: 400;
    color:var(--section-slate-color,var(--section-slate,var(--slate)));
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  @media (min-width: 900px) {
    font-size: 16px;
  }
`;

export const ListBarLeft = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  font-size: 14px;
  overflow: hidden;
  box-sizing: border-box;
  padding-right: 64px;
  line-height: 24px;
  .icon {
    height: 24px;
    min-width: 24px;
    border-radius: 2px;
    padding: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    svg {
      width: auto;
    }
  }
  .icon-wrapper {
    display: flex;
    align-items: center;
  }
  .title {
    width: 100%;
    padding-left: 16px;
  }
  @media (min-width: 600px) {
    font-size: 16px;
    padding-right: 88px;
  }
  @media (min-width: 1280px) {
    padding-right: 218px;
  }
`;
