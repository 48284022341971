import { fromJS, Map } from "immutable";
import localForage from "localforage";
import logger from "Libs/logger";
import { setupUIKitTheme, getColorMode } from "Libs/themes";
import { isJson } from "Libs/utils";

const LOAD_THEME_START = "app/themes/load_theme_start";
const LOAD_THEME_SUCCESS = "app/themes/load_theme_success";
const LOAD_THEME_FAILURE = "app/themes/load_theme_failure";

const LOAD_THEME_FROM_PROFILE_START =
  "app/themes/load_theme_from_profile_start";
const LOAD_THEME_FROM_PROFILE_SUCCESS =
  "app/themes/load_theme_from_profile_success";
const LOAD_THEME_FROM_PROFILE_FAILURE =
  "app/themes/load_theme_from_profile_failure";

export const loadTheme = () => {
  return async dispatch => {
    dispatch({ type: LOAD_THEME_START });

    try {
      await setupUIKitTheme();

      const colorSchemeFromCache = await getColorMode();

      dispatch({
        type: LOAD_THEME_SUCCESS,
        payload: {
          name: colorSchemeFromCache
        }
      });
    } catch (err) {
      if (![404, 403].includes(err.code)) {
        const errorMessage = isJson(err)
          ? err
          : "An error occurred while attempting to load theme.";
        logger(errorMessage, {
          action: "loadTheme"
        });
      }
      dispatch({ type: LOAD_THEME_FAILURE, error: true, payload: err });
    }
  };
};

export const setThemeFromProfile = () => async (dispatch, getState) => {
  dispatch({ type: LOAD_THEME_FROM_PROFILE_START });
  const username = getState().app.get("me").get("username");

  const colorSchemeFromCache = await localForage.getItem("ui_colorscheme");
  const colorSchemeFromProfile = getState().profile.getIn([
    "data",
    username,
    "ui_colorscheme"
  ]);

  try {
    if (colorSchemeFromCache !== colorSchemeFromProfile) {
      await localForage.setItem("ui_colorscheme", colorSchemeFromProfile);
    }

    dispatch(loadTheme());
    dispatch({
      type: LOAD_THEME_FROM_PROFILE_SUCCESS,
      meta: colorSchemeFromProfile
    });
  } catch (err) {
    dispatch({ type: LOAD_THEME_FROM_PROFILE_FAILURE, payload: err });
  }
};

export default function themeReducer(state = new Map(), action) {
  switch (action.type) {
    case LOAD_THEME_START:
      return state.set("loading", true);
    case LOAD_THEME_SUCCESS:
      return state
        .set("loading", false)
        .set("data", fromJS(action.payload))
        .set("errors", false);
    case LOAD_THEME_FAILURE:
      return state.set("loading", false).set("errors", action.payload);
    default:
      return state;
  }
}
