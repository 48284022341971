import styled, { css as styledCSS } from "styled-components";
import { css, BUTTON, ICON, LINK, MENU, getCSSVarString } from "Libs/themes";

export const Wrapper = styled.div`
  &.info-button {

    > button {
      span.icon {
        height: 16px;
        width: 16px;
        display: flex;
        align-items: center;

        svg {
          max-width: 100%;
          min-width: 100%;
        }
      }
    }

    hr {
      width: calc(100% + 40px);
      margin-left: -24px;
    }
  }

  &.environments-select {
    height: 56px;
    box-sizing: border-box;
    width: 100%;
    position: static;
    border-right: 1px solid rgba(255, 255, 255, 0.1);

    &.active {
      border-right: none;
    }

    &:hover,
    &.active {
      background-color:var(--menu-dark-tint-background-color,var(--menu-dark-tint,var(--dark-tint)));
    }

    &:not(.active):hover {
      border-style: solid;
      border-color: rgba(255, 255, 255, 0.1);
      border-width: 0 0 0 1px;
      margin-left: -1px;
    }

    &:focus-within {
      span.text {
        position: relative;

        span.active {
          position: absolute;
          top: 35px;
          left: 12px;
          display: ${props =>
            props.theme.name === "contrast" ? "inline-block" : "none"};
          height: 4px;
          width: 4px;
          border-radius: 4px;
          background: #fff;
        }
      }
    }

    button .chevron svg {
      path {
        fill:var(--icon-periwinkle-grey-fill,var(--icon-periwinkle-grey,var(--periwinkle-grey)));
      }
    }

    &:hover,
    &:focus-within,
    &.active {
      button .chevron svg {
        opacity: 1;
      }
    }

    button {
      background: transparent;
      border: none;
      color: #fff;
      font-size: 16px;
      line-height: 56px;
      height: 56px;
      box-sizing: border-box;
      padding: 0 0 0 24px !important;
      width: 100%;

      span.chevron {
        min-width: 42px;
        height: 24px;
        border-left: 1px solid rgba(255, 255, 255, 0.1);
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
      }

      &:hover,
      &:focus {
        outline: none;
        border: none;
        line-height: 40px;
        margin: 0;

        svg {
          opacity: 1;
        }
      }

      &.open {
        color: #fff !important;

        svg {
          path {
            fill: #fff !important;
          }
        }

        &:hover,
        &:focus,
        &:active {
          outline: none;
          box-shadow: none;
        }
      }
    }

    .tooltip {
      left: 24px;
      right: auto;
      margin: 4px 0 0 0 !important;
      width: calc(100vw - 48px);
      border-radius: 2px;
      z-index: 3 !important;

      > section {
        border-radius: 2px;
        padding: 0;
      }

      .tooltip-content {
        clear: both;
      }

      .environments-menu {
        margin: 0;
      }
    }

    .environments-links {
      padding: 0;
      max-height: 332px;
      border-top: none;

      &.reduced-list {
        padding-bottom: 0;
      }
    }

    @media (min-width: 768px) {
      .tooltip {
        width: 100%;
        min-width: 300px;
        max-width: 90vw;
      }

      &.environments-select {
        position: relative;

        .tooltip {
          width: 300px;
          left: 0;
        }
      }
    }
  }

  &.inline-dropdown.open-wrapper {
    button, button:hover {
      color:var(--button-skye-color,var(--button-skye,var(--skye)));
      position: relative;
      z-index: 2;
      border-radius: 0;
      box-shadow: none;
      border-color: transparent;
      background: transparent;

      svg .chevron {
        fill:var(--button-skye-fill,var(--button-skye,var(--skye)));
      }
    }

    .tooltip {
      z-index: 1;

      > div {
        padding-top: 60px;
      }
    }
  }
}

&.more {
  ul, li {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  li:not(:last-child) {
    padding-bottom: 8px;
  }

  button {
    width: auto;
    border: none;
    padding: 0 !important;

    &:hover,
    &.open {
      background-color:var(--button-grey-background-color,var(--button-grey,var(--grey)));

      svg path {
        fill:var(--button-slate-fill,var(--button-slate,var(--slate)));
      }
    }

    &.info-icon {
      svg path {
        fill:var(--icon-granite-fill,var(--icon-granite,var(--granite)));
      }

      &:hover svg path {
        fill:var(--icon-slate-fill,var(--icon-slate,var(--slate)));
      }
    }
  }

  .tooltip {
    width: 164px;

    a {
      color:var(--link-night-color,var(--link-night,var(--night)));
      text-decoration: none;
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 5px;

      &:hover {
        color:var(--link-skye-color,var(--link-skye,var(--skye)));
        cursor: pointer;
      }

      &.disabled {
        background: none;
        color:var(--link-granite-color,var(--link-granite,var(--granite)));
      }
    }
  }
}

@media (min-width: 768px) {
  &.environments-select {
    width: 232px;
  }
}

@media (min-width: 960px) {
  position: relative;
}

${({ inverted }) =>
  inverted &&
  styledCSS`
    &.more button {
      &:hover,
      &.open {
        background-color:var(--button-dark-tint-background-color,var(--button-dark-tint,var(--dark-tint)));

        svg path {
          fill:var(--button-snow-fill,var(--button-snow,var(--snow)));
        }
      }
    }
  `}
`;

export const Window = styled.div`
  position: absolute;
  display: ${props => (props.open ? "block" : "none")};
  margin-top: 4px;
  right: 0;
  left: 0;
  font-size: 12px;
  z-index: 2;
  box-shadow: var(--menu-box-shadow,var(--box-shadow))
    var(--menu-ebony-shadow-box-shadow-color,var(--menu-ebony-shadow,var(--ebony-shadow)));
  border-radius: 2px 0 2px 2px;
  background-color:var(--menu-snow-background-color,var(--menu-snow,var(--snow)));
  hr {
    background-color:var(--menu-periwinkle-grey-background-color,var(--menu-periwinkle-grey,var(--periwinkle-grey)));
  }
  > section {
    padding: 16px;
    box-sizing: border-box;
    max-width: 100%;
    margin: 0;
    overflow: hidden;
    box-shadow: none;
  }

  &.tooltip > section > span {
    padding: 0;
  }
  .close {
    float: right;
    padding: 0 !important;
    justify-content: center;
    align-items: center;
    margin-top: -4px;
    margin-right: -4px;

    .icon {
      padding: 0 !important;
    }
  }
  h3 {
    margin: 0 0 20px;
    font-size: 14px;
  }
  &.inline {
    padding: 0;
    top: 0;
    border: none;
    margin-top: -10px;
    margin-left: -10px;
  }
  @media (min-width: 960px) {
    left: auto;
    right: 0;
    width: ${props => props.tooltipWidth || 600}px;
    &.environment-urls-window {
      width: 440px;
    }
  }
`;

export const ButtonToggle = styled.button`
  background-color: transparent;
  ${props =>
    props.withBorder
      ? styledCSS`
          border: 1px solid ${getCSSVarString(
            BUTTON,
            "periwinkle-grey",
            "border-color"
          )};
        `
      : "border: none"};
  color:var(--button-slate-color,var(--button-slate,var(--slate)));
  padding: 0 4px 0 16px !important;
  line-height: 30px;
  box-shadow: none;
  border-radius: 2px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome and Opera */

  .text {
    &:first-letter {
      text-transform: uppercase;
    }
  }
  svg {
    width: 24px;
    min-width: 24px;
    height: auto;
    margin: 0;
  }

  &:focus {
    box-shadow: 0 0 2px
      var(--button-skye-dark-box-shadow-color,var(--button-skye-dark,var(--skye-dark)));
    border: 1px solid var(--button-skye-dark-border-color,var(--button-skye-dark,var(--skye-dark)));
    outline: none;
  }

  &:hover,
  &.open {
    border-color:var(--button-skye-border-color,var(--button-skye,var(--skye)));
  }

  &.open,
  &:focus,
  &:hover {
    color:var(--button-skye-color,var(--button-skye,var(--skye)));
    svg rect {
      fill:var(--button-skye-fill,var(--button-skye,var(--skye)));
    }

    ${({ theme }) =>
      theme.name === "contrast" &&
      styledCSS`
        color: #0a5cff;
        border-color: #0a5cff;
        outline: 0;
        box-shadow: none;
        svg path {
          fill: #0a5cff;
        }
      `};
  }
  &.open svg {
    transform: rotate(180deg);
  }
  &.info-icon {
    height: 32px;
    width: 32px;
    min-width: 32px;
    display: flex;
    justify-content: center;
    padding: 4px;
  }
  &.with-label {
    label {
      text-transform: uppercase;
      letter-spacing: 1px;
      margin-bottom: 0;
      font-size: 10px;
      color: #fff;
      opacity: 0.7;
    }
    .text {
      line-height: 22px;
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 16px;
    }
    .text {
      padding-right: 16px;
    }
  }
  gap: 8px;

  @media (max-width: 768px) {
    gap: 0;
    padding: 0 !important;
  }
`;
