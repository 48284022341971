import styled from "styled-components";
import ButtonWrapper from "Components/ButtonWrapper";

import { css, SECTION } from "Libs/themes";

export const Wrapper = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 32px;
  margin-bottom: 24px;
  background-color:var(--section-snow-background-color,var(--section-snow,var(--snow)));
  border-radius: 2px;
  max-width: 50%;
`;

export const Form = styled.form`
  padding: 32px;
  width: 100%;
  textarea {
    width: 100%;
  }
  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    > div {
      width: 100%;
      box-sizing: border-box;
    }
    > div:first-child {
      width: 33%;
      padding-right: 20px;
    }
  }
`;

export const ButtonsWrapper = styled(ButtonWrapper)`
  margin-top: 40px;
`;

export const AppItem = styled.div`
  span + button {
    margin: 10px;
  }
`;

export const BoxLayout = styled.div`
  &.environments,
  &.activity {
    padding: 32px;
    h2 {
      margin: 0 0 31px;
      line-height: 22px;
    }
  }
  &.activity {
    padding-bottom: 16px;
  }
  &.environments {
    min-height: 848px;
    box-sizing: border-box;
    h2 {
      margin-bottom: 35px;
    }
    &.no-code {
      min-height: 412px;
    }
  }
`;
