import { Map, fromJS } from "immutable";
import localForage from "localforage";
import moment from "moment";

import {
  getOrganizationDescriptionId,
  getOrganizationDescriptionIdFromProject,
  hasHtml
} from "Libs/utils";
import logger from "Libs/logger";

import { PROJECT_ID_FIELD } from "Constants/constants";
import { LOAD_ORGANIZATION_SUCCESS } from "Reducers/organization";

const LOAD_PROJECTS_START = "app/projects/load_start";
export const LOAD_PROJECTS_SUCCESS = "app/projects/load_success";
const LOAD_PROJECTS_FAILURE = "app/projects/load_failure";

const LOAD_LAST_PROJECTS_START = "app/last_projects/load_start";
const LOAD_LAST_PROJECTS_SUCCESS = "app/last_projects/load_success";
const LOAD_LAST_PROJECTS_FAILURE = "app/last_projects/load_failure";

const LOAD_PROJECT_START = "app/project/load_start";
const LOAD_PROJECT_SUCCESS = "app/project/load_success";
const LOAD_PROJECT_FAILURE = "app/project/load_failure";

const RELOAD_PROJECT_START = "app/project/reload_start";
const RELOAD_PROJECT_SUCCESS = "app/project/reload_success";
const RELOAD_PROJECT_FAILURE = "app/project/reload_failure";

export const UPDATE_PROJECT_START = "app/project/update_start";
export const UPDATE_PROJECT_SUCCESS = "app/project/update_success";
export const UPDATE_PROJECT_FAILURE = "app/project/update_failure";

const loadProjectSuccess = project => {
  return { type: LOAD_PROJECT_SUCCESS, payload: project };
};

const updateDeletedProjectsCache = (projectIds, deletedProjectIdsCache) => {
  const projectsWithDeletionInProgress = deletedProjectIdsCache.filter(id =>
    projectIds.includes(id)
  );
  localForage.setItem("deletedProjectIds", projectsWithDeletionInProgress);
};

export const loadProjects = () => {
  return async (dispatch, getState) => {
    dispatch({ type: LOAD_PROJECTS_START });

    try {
      const platformLib = await import("Libs/platform");
      const client = platformLib.default;

      const availableRegions = process.env.AVAILABLE_REGIONS;
      const usersProjects = await client.getProjects();
      const projects = availableRegions
        ? usersProjects.filter(project =>
            availableRegions.includes(project.region)
          )
        : usersProjects;
      const projectIds = projects.map(project => project.id);
      const deletedProjectIds =
        (await localForage.getItem("deletedProjectIds")) || [];

      const availableProjects = projects.filter(
        project => !deletedProjectIds.includes(project.id)
      );

      const organizations = getState().organization?.get("data", new Map());

      dispatch({
        type: LOAD_PROJECTS_SUCCESS,
        payload: {
          projects: availableProjects
        },
        meta: {
          organizations
        }
      });

      updateDeletedProjectsCache(projectIds, deletedProjectIds);
    } catch (err) {
      logger(err, {
        action: "projects_load"
      });
      dispatch({ type: LOAD_PROJECTS_FAILURE, error: true, payload: err });
    }
  };
};

export const loadLastVisitedProjects = () => {
  return async (dispatch, getState) => {
    const project = getState().project;

    if (!project) {
      return false;
    }

    let lastVisitedProjects = project.get("lastVisitedProjects");

    if (lastVisitedProjects && lastVisitedProjects.size) {
      return false;
    }

    try {
      dispatch({ type: LOAD_LAST_PROJECTS_START });

      lastVisitedProjects = await localForage.getItem("lastVisitedProjects");

      dispatch({
        type: LOAD_LAST_PROJECTS_SUCCESS,
        payload: lastVisitedProjects
      });
    } catch (err) {
      dispatch({ type: LOAD_LAST_PROJECTS_FAILURE });
    }
  };
};

const subscribe = project => {
  return async (dispatch, getState) => {
    const projectSubscription = await project.subscribe();

    const platformLib = await import("platformsh-client");
    const models = platformLib.models;

    const activityReducerModule = await import("Reducers/activity");
    const loadActivitySuccess = activityReducerModule.loadActivitySuccess;

    projectSubscription.addEventListener(
      "resource/activity",
      message => {
        if (!message.data) {
          return false;
        }

        const activityRawMessage = JSON.parse(message.data);
        dispatch(
          loadActivitySuccess(
            new models.Activity(
              activityRawMessage.data,
              activityRawMessage.data._links.self.href
            )
          )
        );
      },
      false
    );

    projectSubscription.addEventListener(
      "resource/project",
      message => {
        if (!message.data) {
          return false;
        }

        const projectRawMessage = JSON.parse(message.data);

        dispatch(
          loadProjectSuccess(
            new models.Project(
              projectRawMessage.data,
              projectRawMessage.data._links.self.href
            )
          )
        );
      },
      false
    );

    const environmentReducerModule = await import("Reducers/environment");
    const loadEnvironmentFromEventSuccess =
      environmentReducerModule.loadEnvironmentFromEventSuccess;

    projectSubscription.addEventListener(
      "resource/environment",
      message => {
        if (!message.data) {
          return false;
        }

        const environmentRawMessage = JSON.parse(message.data);

        const organizationDescriptionId = getOrganizationDescriptionId(
          getState,
          environmentRawMessage.data.project
        );

        dispatch(
          loadEnvironmentFromEventSuccess({
            environment: new models.Environment(
              environmentRawMessage.data,
              environmentRawMessage.data._links.self.href
            ),
            organizationId: organizationDescriptionId
          })
        );
      },
      false
    );
  };
};

export const loadProject = (projectId, loadActivitiesAndSubscribe = true) => {
  return async (dispatch, getState) => {
    dispatch({ type: LOAD_PROJECT_START });
    try {
      const projectReducer = getState().project || new Map();
      let project = projectReducer.getIn(["data", projectId]);

      // Get the project id from the project description id in the
      // Project loaded from /me
      let projectMe = getState()
        .app.getIn(["me", "projects"])
        .find(p => p.get(PROJECT_ID_FIELD) === projectId);

      if (!projectMe) {
        const platformLib = await import("Libs/platform");
        const client = platformLib.default;
        const { entities, request } = platformLib;

        projectMe = new Map(
          await request(
            `${client.getConfig().api_url}/platform/projects/${projectId}`,
            "GET"
          )
        );

        project = await entities.Project.get(
          { id: projectId },
          projectMe.endpoint
        );

        if (!project) {
          throw "project.notfound";
        }
      }

      if (!project) {
        const platformLib = await import("Libs/platform");
        const client = platformLib.default;

        project = await client.getProject(projectMe.get("id")); // eslint-disable-line

        if (!project) {
          throw "project.notfound";
        }
      }

      // TODO check that the organization is loaded (if the user is member of this org)
      // If not, we just load the public information for this org (fragment of the data)

      let lastVisitedProjects = getState().project.get("lastVisitedProjects");

      if (!lastVisitedProjects) {
        lastVisitedProjects =
          (await localForage.getItem("lastVisitedProjects")) || [];
      }

      lastVisitedProjects = fromJS(lastVisitedProjects);

      const currentProjectIndex = lastVisitedProjects.findIndex(p => {
        return p.get("id") === project.id;
      });

      let newLastVisitedProjects = lastVisitedProjects;

      if (currentProjectIndex !== -1) {
        newLastVisitedProjects =
          newLastVisitedProjects.delete(currentProjectIndex);
      }

      newLastVisitedProjects = newLastVisitedProjects.unshift(
        fromJS({ ...project })
      );

      if (newLastVisitedProjects.size > 10) {
        newLastVisitedProjects = newLastVisitedProjects.pop();
      }

      // @todo: Refactor to get rid of instances where we can't use .toJS().
      let formattedProjectMe = projectMe;
      if (typeof projectMe.toJS === "function") {
        formattedProjectMe = projectMe.toJS();
      }
      if (process.env.ENABLE_ORGANIZATION) {
        let organization = getState().organization.getIn([
          "data",
          formattedProjectMe.organization_id
        ]);

        if (!organization) {
          const platformLib = await import("Libs/platform");
          const client = platformLib.default;
          // Load the organization
          organization = await client.getOrganization(
            formattedProjectMe.organization_id
          );
          dispatch({
            type: LOAD_ORGANIZATION_SUCCESS,
            payload: organization
          });
        }
      }

      const organizations = getState().organization.get("data");

      dispatch({
        type: LOAD_PROJECT_SUCCESS,
        payload: project,
        meta: {
          lastVisitedProjects: newLastVisitedProjects,
          projectMe: formattedProjectMe,
          organizations
        }
      });

      if (loadActivitiesAndSubscribe) {
        localForage.setItem(
          "lastVisitedProjects",
          newLastVisitedProjects.toJS()
        );

        dispatch(subscribe(project));
      }
    } catch (err) {
      if (![404, 403, 400].includes(err.code) && !hasHtml(err)) {
        logger(err, {
          action: "project_load",
          projectId
        });
      }
      dispatch({ type: LOAD_PROJECT_FAILURE, error: true, payload: err });
    }
  };
};

/**
 * Reload poject object
 *
 * @param {string} projectId
 *
 */
export const reloadProject = ({ projectId }) => {
  return async (dispatch, getState) => {
    dispatch({ type: RELOAD_PROJECT_START });

    try {
      const projectMe = getState()
        .app.getIn(["me", "projects"])
        .find(p => p.get(PROJECT_ID_FIELD) === projectId)
        ?.toJS();

      const platformLib = await import("Libs/platform");
      const client = platformLib.default;
      const project = await client.getProject(projectId);

      dispatch({
        type: RELOAD_PROJECT_SUCCESS,
        payload: project,
        meta: {
          projectMe,
          organizations: getState().organization.get("data")
        }
      });
    } catch (err) {
      if (![404, 403, 400].includes(err.code) && !hasHtml(err)) {
        logger(err, {
          action: "project_reload",
          projectId
        });
      }
      dispatch({ type: RELOAD_PROJECT_FAILURE, error: true, payload: err });
    }
  };
};

export const updateProject = (
  organizationDescriptionId,
  projectDescriptionId,
  data
) => {
  return async (dispatch, getState) => {
    dispatch({ type: UPDATE_PROJECT_START });

    try {
      const projectMe = getState()
        .app.getIn(["me", "projects"])
        .find(p => p.get(PROJECT_ID_FIELD) === projectDescriptionId);

      const projectReducer = getState().project || new Map();
      let project = projectReducer.getIn([
        "data",
        organizationDescriptionId,
        projectDescriptionId
      ]);

      if (!project) {
        return dispatch({
          type: UPDATE_PROJECT_FAILURE,
          error: true,
          payload: "project does not exist"
        });
      }

      const organizations = getState().organization.get("data", new Map());
      const result = await project.update(data);

      dispatch({
        type: UPDATE_PROJECT_SUCCESS,
        payload: result.getEntity(),
        meta: { organizations, projectMe: projectMe.toJS() }
      });
    } catch (err) {
      logger(err, {
        action: "project_update",
        organizationDescriptionId,
        projectDescriptionId,
        data
      });
      dispatch({ type: UPDATE_PROJECT_FAILURE, error: true, payload: err });
    }
  };
};

export default function projectReducer(state = new Map(), action) {
  switch (action.type) {
    case LOAD_PROJECTS_START:
      return state.set("loadingList", true);
    case UPDATE_PROJECT_START:
      return state.delete("projectUpdateError").set("loading", true);
    case LOAD_PROJECT_START:
      return state.delete("projectLoadingError").set("loading", true);
    case LOAD_LAST_PROJECTS_SUCCESS:
      return state.set("lastVisitedProjects", fromJS(action.payload || []));
    case LOAD_PROJECTS_SUCCESS:
      return state
        .set("loadingList", false)
        .set("loadedList", true)
        .set(
          "list",
          fromJS(
            action.payload.projects.reduce((organizationsProjects, project) => {
              const organizationId = getOrganizationDescriptionIdFromProject(
                project,
                action.meta.organizations?.toJS()
              );
              if (!organizationsProjects[organizationId]) {
                organizationsProjects[organizationId] = {};
              }

              organizationsProjects[organizationId][project[PROJECT_ID_FIELD]] =
                fromJS(project);

              return organizationsProjects;
            }, {})
          )
        )
        .set("all", fromJS(action.payload.projects));
    case UPDATE_PROJECT_SUCCESS:
    case LOAD_PROJECT_SUCCESS:
      return state
        .setIn(
          [
            "data",
            getOrganizationDescriptionIdFromProject(
              action.meta.projectMe,
              action.meta.organizations?.toJS()
            ),
            action.payload[PROJECT_ID_FIELD]
          ],
          action.payload.updateLocal({
            ...action.payload.data,
            owner_info: action.meta.projectMe.owner_info,
            organization_id: action.meta.projectMe.organization_id,
            plan_uri: action.meta.projectMe && action.meta.projectMe.plan_uri,
            region_label:
              action.meta.projectMe && action.meta.projectMe.region_label,
            plan: action.meta.projectMe && action.meta.projectMe.plan,
            subscription_id:
              action.meta.projectMe && action.meta.projectMe.subscription_id
          })
        )
        .set("lastVisitedProjects", action.meta.lastVisitedProjects)
        .set("loading", false)
        .setIn(
          ["orgByProjectId", action.payload[PROJECT_ID_FIELD]],
          getOrganizationDescriptionIdFromProject(
            action.meta.projectMe,
            action.meta.organizations?.toJS()
          )
        )
        .delete("projectLoadingError");
    case UPDATE_PROJECT_FAILURE:
      return state
        .set("loading", false)
        .set("projectUpdateError", action.payload);
    case LOAD_PROJECT_FAILURE:
    case LOAD_PROJECTS_FAILURE:
      return state
        .set("loading", false)
        .set("projectLoadingError", fromJS(action.payload));

    case RELOAD_PROJECT_START:
      return state.set("loading", true);
    case RELOAD_PROJECT_SUCCESS:
      return state
        .set("loading", false)
        .delete("projectLoadingError")
        .setIn(
          [
            "data",
            getOrganizationDescriptionIdFromProject(
              action.meta.projectMe,
              action.meta.organizations?.toJS()
            ),
            action.payload[PROJECT_ID_FIELD]
          ],
          action.payload.updateLocal({
            ...action.payload.data,
            owner_info: action.meta.projectMe.owner_info,
            organization_id: action.meta.projectMe.organization_id,
            plan: action.meta.projectMe.plan,
            plan_uri: action.meta.projectMe.plan_uri,
            region_label: action.meta.projectMe.region_label,
            subscription_id: action.meta.projectMe.subscription_id
          })
        )
        .setIn(
          ["orgByProjectId", action.payload[PROJECT_ID_FIELD]],
          getOrganizationDescriptionIdFromProject(
            action.meta.projectMe,
            action.meta.organizations?.toJS()
          )
        );
    case RELOAD_PROJECT_FAILURE:
      return state
        .set("loading", false)
        .set("projectLoadingError", fromJS(action.payload));

    default:
      return state;
  }
}
export const projectsSelector = (state, props) =>
  state.project?.getIn(
    ["list", props.organizationId],
    // We don't have organisation yet
    // So props.organizationId is undefined
    state.project?.get("all")
  );

export const filteredProjectsSelector = (state, sortedProjects) => {
  const search = state.search?.get("query", "");
  return sortedProjects?.filter(project => {
    const title = project.title;
    const id = project.id;

    if (!search) {
      return true;
    }

    if (id?.toUpperCase().includes(search?.toUpperCase())) {
      return true;
    }

    if (!title) {
      return false;
    }

    return title.toUpperCase().includes(search?.toUpperCase());
  });
};

export const sortedProjectsSelector = (
  state,
  { organizationId, sortType = "project_title", sortOrder = "ascend" },
  organizations
) => {
  const projects = projectsSelector(state, { organizationId });
  const sortCriteria = sortType === "project_title" ? "title" : sortType;
  if (sortCriteria === "created_at") {
    return projects.sort((a, b) => {
      if (sortOrder === "descend") {
        return moment(b[sortCriteria]).diff(a[sortCriteria]);
      }
      moment(a[sortCriteria]).diff(b[sortCriteria]);
    });
  } else if (sortCriteria === "organization") {
    return projects.sort((a, b) => {
      const o1 = organizations[a?.organization_id];
      const o2 = organizations[b?.organization_id];
      const o1Label = o1?.label.toLowerCase();
      const o2Label = o2?.label.toLowerCase();
      if (o1Label && o2Label) {
        if (sortOrder === "descend") {
          if (o1Label > o2Label) return -1;
          if (o1Label < o2Label) return 1;
          return 0;
        }
        if (o1Label < o2Label) return -1;
        if (o1Label > o2Label) return 1;
        return 0;
      }
    });
  } else {
    return projects.sort((a, b) => {
      if (sortOrder === "ascend") {
        if (a[sortCriteria]?.toLowerCase() < b[sortCriteria]?.toLowerCase())
          return -1;
        if (a[sortCriteria]?.toLowerCase() > b[sortCriteria]?.toLowerCase())
          return 1;
        return 0;
      }
      if (a[sortCriteria]?.toLowerCase() > b[sortCriteria]?.toLowerCase())
        return -1;
      if (a[sortCriteria]?.toLowerCase() < b[sortCriteria]?.toLowerCase())
        return 1;
      return 0;
    });
  }
};

export const projectSelector = (state, props) =>
  state.project.getIn(["list", props.organizationId, props.projectId], false);

export const gitProjectSelector = (state, props) =>
  state.project.getIn(["data", props.organizationId, props.projectId], false);

export const isEnvironmentTypeProjectSelector = (state, props) => {
  const project = gitProjectSelector(state, props);
  return project?.hasLink && project?.hasLink("#environment-types");
};
