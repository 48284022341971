import { fromJS, Map } from "immutable";

import client, { entities } from "Libs/platform";
import logger from "Libs/logger";

import { setThemeFromProfile } from "Reducers/app/theme";

const LOAD_CURRENT_USER_PROFILE_START =
  "app/profile/current_user_profile/load_start";
const LOAD_CURRENT_USER_PROFILE_FAILURE =
  "app/profile/current_user_profile/load_failure";
const LOAD_PROFILE_START = "app/profile/get_start";
const LOAD_PROFILE_SUCCESS = "app/profile/get_success";
const LOAD_PROFILE_FAILURE = "app/profile/get_failure";

const UPDATE_PROFILE_START = "app/profile/update_start";
const UPDATE_PROFILE_SUCCESS = "app/profile/update_success";
const UPDATE_PROFILE_FAILURE = "app/profile/update_failure";

const CLEAR_ERRORS = "app/profile/clear_errors";
const UPDATE_PROFILE_PICTURE_ERROR = "app/profile/update_picture_error";
const UPDATE_PROFILE_PICTURE_SUCCESS = "app/profile/update_picture_success";

const REQUEST_EMAIL_RESET_START = "app/profile/request_email_reset_start";
const REQUEST_EMAIL_RESET_SUCCESS = "app/profile/request_email_reset_success";
const REQUEST_EMAIL_RESET_FAILURE = "app/profile/request_email_reset_failure";
const randomStr = () => Math.random().toString(36).substring(7);

const parseError = error => {
  let parsedError = error;
  try {
    parsedError = JSON.parse(error);
  } catch (e) {
    parsedError = error;
  }

  return parsedError;
};

export const clearErrors = () => ({ type: CLEAR_ERRORS });
export const pictureError = (error, picture) => ({
  type: UPDATE_PROFILE_PICTURE_ERROR,
  payload: {
    message: error.message,
    size: picture.size,
    name: picture.name
  }
});

const getUserNameById = (id, state) =>
  Object.entries(state.profile.get("data").toJS()).find(
    ([, data]) => data.id === id
  )[0];

export const loadCurrentUserProfile = () => {
  return async (dispatch, getState) => {
    dispatch({ type: LOAD_CURRENT_USER_PROFILE_START });

    const userId = getState().app.getIn(["me", "id"]);

    try {
      dispatch(loadUserProfile(userId));
    } catch (err) {
      dispatch({
        type: LOAD_CURRENT_USER_PROFILE_FAILURE,
        payload: err
      });
    }
  };
};

export const loadUserProfile = id => {
  return async dispatch => {
    dispatch({ type: LOAD_PROFILE_START });

    try {
      const [accountsProfile, authUser] = await Promise.all([
        client.getUserProfile(id),
        client.getUser(id)
      ]);

      const profile = {
        ...accountsProfile.data,
        ...authUser.data
      };

      if (accountsProfile?.picture) {
        profile.picture = accountsProfile.picture;
      }

      dispatch({
        type: LOAD_PROFILE_SUCCESS,
        payload: profile,
        meta: {
          modifiableFields: {
            auth: authUser._modifiableField,
            accounts: accountsProfile._modifiableField
          },
          key: randomStr()
        }
      });
      dispatch(setThemeFromProfile());
    } catch (error) {
      logger(error, {
        action: "LOAD_PROFILE_FAILURE"
      });
      dispatch({ type: LOAD_PROFILE_FAILURE, error: true, payload: error });
    }
  };
};

export const updateUserProfile = (id, data) => {
  return async (dispatch, getState) => {
    dispatch({ type: UPDATE_PROFILE_START });
    const { AccountsProfile, AuthUser } = entities;

    const username = getUserNameById(id, getState());

    try {
      if (data.email) {
        dispatch(updateUserEmail(id, data.email));
      }
      const authFields = getState()
        .profile.getIn(["modifiableFields", "auth"])
        .toJS();
      const accountsFields = getState()
        .profile.getIn(["modifiableFields", "accounts"])
        .toJS()
        .filter(field => !authFields.includes(field)); // don't save field already saved to auth api

      const picture = data.picture;
      delete data.picture;

      let updatesForAuthUser = Object.entries(data).reduce(
        (fields, [key, value]) => {
          if (authFields.includes(key)) {
            fields[key] = value;
          }
          return fields;
        },
        {}
      );
      let updatesForAccountsProfile = Object.entries(data).reduce(
        (fields, [key, value]) => {
          if (accountsFields.includes(key)) {
            fields[key] = value;
          }
          return fields;
        },
        {}
      );

      if (picture === null) {
        await client.deleteProfilePicture(id);
      } else if (picture) {
        const formData = new FormData();
        formData.append("file", picture);
        try {
          const { url } = await client.updateProfilePicture(id, formData);
          if (url && !Object.keys(updatesForAccountsProfile).length) {
            dispatch({
              type: UPDATE_PROFILE_PICTURE_SUCCESS,
              meta: { id: username },
              payload: url
            });
          }
        } catch (error) {
          dispatch(pictureError(error, picture));
        }
      }

      const previousProfile = getState()
        .profile.getIn(["data", username])
        .toJS();
      let accountsProfile;
      let authUser;

      if (Object.keys(updatesForAccountsProfile).length > 0) {
        accountsProfile = await AccountsProfile.update(
          id,
          updatesForAccountsProfile
        );
      }

      if (Object.keys(updatesForAuthUser).length > 0) {
        authUser = await AuthUser.update(id, updatesForAuthUser);
      }

      const profile = {
        ...previousProfile,
        ...accountsProfile,
        ...authUser
      };

      if (accountsProfile?.picture) {
        profile.picture = accountsProfile.picture;
      }

      if (Object.keys(profile).length > 0) {
        dispatch({
          type: UPDATE_PROFILE_SUCCESS,
          payload: profile,
          meta: {
            key: randomStr()
          }
        });
      }
    } catch (err) {
      const error = parseError(err);

      // Skips logging for validation errors
      if (error?.title !== "Bad Request") {
        logger(err, {
          action: "UPDATE_PROFILE_FAILURE"
        });
      }

      dispatch({ type: UPDATE_PROFILE_FAILURE, error: true, payload: error });
    }
    dispatch(setThemeFromProfile());
  };
};

export const updateUserEmail = (id, emailAddress) => {
  return async dispatch => {
    dispatch({ type: REQUEST_EMAIL_RESET_START });
    const { AuthUser } = entities;
    try {
      await AuthUser.updateEmailAddress(id, emailAddress).catch(err => {
        throw new Error(err);
      });
      dispatch({
        type: REQUEST_EMAIL_RESET_SUCCESS,
        payload: { emailAddress }
      });
    } catch (err) {
      logger(err, { action: "REQUEST_EMAIL_RESET_FAILURE" });
      dispatch({ type: REQUEST_EMAIL_RESET_FAILURE, payload: err });
    }
  };
};

export const deleteProfilePicture = () => {
  return async (dispatch, getState) => {
    const uuid = getState().app.getIn(["me", "uuid"]);
    try {
      await client.deleteProfilePicture(uuid);
      dispatch(loadCurrentUserProfile());
    } catch (error) {
      logger(error, { action: "DELETE_USER_PROFILE_FAILED" });
    }
  };
};

export default function planReducer(state = new Map(), action) {
  switch (action.type) {
    case LOAD_PROFILE_START:
    case UPDATE_PROFILE_START:
      return state.set("loading", true);
    case UPDATE_PROFILE_PICTURE_ERROR:
      return state.set("errors", action.payload);
    case UPDATE_PROFILE_PICTURE_SUCCESS:
      return state.updateIn(["data", action.meta.id], e =>
        e.mergeDeep({
          picture: action.payload
        })
      );
    case CLEAR_ERRORS:
      return state.remove("errors");
    case LOAD_PROFILE_SUCCESS:
      return state
        .setIn(["data", action.payload.username], fromJS(action.payload))
        .setIn(["cacheKeys", action.payload.username], randomStr())
        .setIn(["modifiableFields"], fromJS(action.meta.modifiableFields))
        .set("loading", false);
    case UPDATE_PROFILE_SUCCESS:
      return state
        .setIn(["data", action.payload.username], fromJS(action.payload))
        .setIn(["cacheKeys", action.payload.username], randomStr())
        .set("loading", false);
    case UPDATE_PROFILE_FAILURE:
    case LOAD_CURRENT_USER_PROFILE_FAILURE:
    case LOAD_PROFILE_FAILURE:
      return state.set("errors", action.payload).set("loading", false);
    case REQUEST_EMAIL_RESET_SUCCESS:
      return state.set("emailReset", action.payload.emailAddress);
    default:
      return state;
  }
}
