import styled, { keyframes } from "styled-components";

import { getCSSVarString, LINK, SECTION, css, MENU_ITEM } from "Libs/themes";

import * as S from "../styles";

export const Link = styled(S.Link)`
  border-bottom-color: var(--link-ebony-light-border-color,var(--link-ebony-light,var(--ebony-light)));
`;

export const OrganizationSwitcher = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 16px;
  padding: 16px 0;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: ${getCSSVarString(
    SECTION,
    "ebony-light",
    "border-color"
  )};
  .organization_switch_title {
    font-size: 14px;
    color:var(--section-periwinkle-grey-color,var(--section-periwinkle-grey,var(--periwinkle-grey)));
  }
  .organization_switch_name_icon {
    transform: rotate(-90deg);
    path {
      fill: white;
    }
  }
`;

const slideIn = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
`;

export const OrganizationMenuWrapper = styled.ul`
  animation: ${slideIn} 0.3s ease-in-out;
  list-style: none;
  padding: 0 24px;
  margin-top: 60px;
  .organization-menu-back {
    align-items: center;
    font-size: 14px;
    .organization-back_text {
      color:var(--section-periwinkle-grey-color,var(--section-periwinkle-grey,var(--periwinkle-grey)));
    }
    margin: 0 12px;
    margin-bottom: 21px;
    display: flex;
    align: center;
    gap: 16px;
    svg * {
      fill: white;
    }
    border: none;
  }
`;

export const List = styled.li`
  background: ${props =>
    props.active
      ?"var(--menu-item-ebony-light-background-color,var(--menu-item-ebony-light,var(--ebony-light)))": "none"};
  .orgainzation-name {
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
    color: ${props =>
      props.active
        ?"var(--menu-item-skye-color,var(--menu-item-skye,var(--skye)))": "currentColor"};
  }
  .orgainzation-switcher {
    border-style: ${props => (props.active ? "none" : "currentColor")};
  }
`;
