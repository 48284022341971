import styled from "styled-components";
import CopyButton from "Components/CopyButton";
import ButtonWrap from "Components/ButtonWrapper";
import Heading3 from "Components/styleguide/Heading3";
import { SECTION, getCSSVarString, ICON, css, DIVIDER } from "Libs/themes";

export const SubHeading = styled.h4`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
`;

export const OperationDescription = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  padding-bottom: 16px;
  .link {
    text-decoration: none;
    color:var(--section-skye-color,var(--section-skye,var(--skye)));
  }
  .operation-name {
    font-weight: 700;
  }
`;

export const ApplicationList = styled.ul`
  list-style: none;
  padding-left: 0;
  margin: 0;
  li:last-child {
    .app-divider {
      display: none;
    }
  }
`;

export const ApplicationCommandWrapper = styled.div`
  display: flex;
  padding: 4px 0 5px 0;
  justify-content: space-between;
  ${SubHeading} {
    line-height: 0;
  }
  .code-command-control {
    gap: 16px;
    display: flex;
    align-items: center;
  }
`;

export const SectionDivider = styled.hr`
  margin-left: -32px;
  margin-right: -32px;
  margin-bottom: 0;
  margin-top: 0;
  background-color: ${getCSSVarString(
    DIVIDER,
    "periwinkle-grey",
    "background-color"
  )};
`;

export const AppDivider = styled.hr`
  margin: 0;
  background-color: var(--divider-stone-background-color,var(--divider-stone,var(--stone)));
`;

export const ApplicationHeaderDivider = styled.hr`
  margin-bottom: 0;
  background-color: var(--section-stone-background-color,var(--section-stone,var(--stone)));
`;

export const ButtonWrapper = styled(ButtonWrap)`
  bottom: 0;
  position: sticky;
  margin-left: -32px;
  background-color: var(--section-snow-background-color,var(--section-snow,var(--snow)));
  margin-right: -32px;
  padding: 32px;
  button {
    text-transform: capitalize;
  }
`;

export const SourceOpsCommandWrapper = styled.div`
  margin: 0;
  .three-line {
    .copy-content-wrapper {
      border-radius: 2px !important;
      overflow: scroll;
      height: ${({ expanded }) => (expanded ? "auto" : 0)} !important;
      pre {
        margin-bottom: 16px !important;
        margin: 0;
        padding-bottom: 12px;
        padding-top: 12px;
        height: fit-content;
      }
    }
  }
  button.copy-to-clipboard {
    display: none;
  }
  background: none;
  border: none;
`;

export const ToggleCommandViewButton = styled.span`
  color: var(--section-skye-color,var(--section-skye,var(--skye)));
  cursor: pointer;
  font-weight: 600;
  line-height: 16px;
  font-size: 14px;
  font-smooth: antialiazed;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

export const CopyIcon = styled(CopyButton)`
  height: auto;
  .copy-button-text {
    display: none;
  }
  svg.icon-copy,
  copy-to-clipboard {
    margin: 0;
    width: 24px;
    height: 24px;
  }
  padding: 3px;
    margin: 0;
  }
  svg path {
   fill:var(--icon-skye-fill,var(--icon-skye,var(--skye)))
  }
`;

export const OperationDropdownContainer = styled.div`
  margin-bottom: 24px;
`;

export const Applications = styled(SubHeading)`
  margin: 0;
`;

export const SourceOperationContainer = styled.div`
  h3 {
    margin-top: 0;
  }
  color: ${({ theme }) => (theme.name !== "dark" ? theme.dark : "unset")};
  p {
    line-height: 24px;
  }
  p.operation-notice {
    padding: unset;
    font-size: 15px;
    color:var(--section-ebony-color,var(--section-ebony,var(--ebony)));
  }
  .operation-notice-action-button {
    button {
      text-transform: capitalize;
    }
    padding: 0;
    margin-top: 40px;
  }
`;

export const Heading = styled(Heading3)`
  line-height: 24px;
`;

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 34px;
`;
