import React, { useMemo } from "react";
import PropTypes from "prop-types";

import useSelectorWithUrlParams from "Hooks/useSelectorWithUrlParams";

import { environmentsSelector } from "Reducers/environment";

import EnvironmentTree from "Components/EnvironmentTree";

const getTreeData = (nodes, environments, depth = 0) => {
  const environmentKeys = environments.map(env => env.id);

  return nodes.map(node => {
    let orphanKeys = [];
    const orphanedEnvs = environments.filter(
      env => env.parent && !environmentKeys.includes(env.parent)
    );

    orphanedEnvs.forEach(env => {
      env.parent = node.id;
      orphanKeys.push(env.id);
    });

    const newNodes = environments.filter(
      environment => environment.parent === node.id
    );

    let children = [];

    if (newNodes.length) {
      children = getTreeData(newNodes, environments, depth + 1);
    }

    if (orphanedEnvs.length) {
      children = children.map(child => {
        if (orphanKeys.includes(child.environmentId)) {
          child.stub = true;
        }
        return child;
      });
    }
    return {
      environmentId: node.id,
      projectId: node.project,
      organizationId: node.organization,
      created_at: node.created_at,
      updated_at: node.updated_at,
      title: node.title,
      isRoot: !node.parent,
      type: node.type,
      isDirty: node.status === "dirty",
      expanded: true,
      status: node.status,
      isMock: node.isMock,
      deployment_target: node.deployment_target,
      depth,
      children
    };
  });
};

const EnvironmentsTree = ({ closeDropDown, layout = "" }) => {
  const environments = useSelectorWithUrlParams(environmentsSelector)
    ?.toArray()
    .map(environment => ({ ...environment.data }));

  const treeData = useMemo(() => {
    if (!environments) return [];
    const rootEnv = environments.filter(environment => !environment.parent);
    return getTreeData(rootEnv, environments);
  }, [environments]);

  return (
    <EnvironmentTree
      data={treeData}
      layout={layout}
      closeDropDown={closeDropDown}
    />
  );
};

EnvironmentsTree.propTypes = {
  closeDropDown: PropTypes.func,
  layout: PropTypes.string
};

export default EnvironmentsTree;
