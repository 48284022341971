export const getDefaultBlankWizardSteps = intl => {
  return [
    {
      id: "cli",
      label: "Download the CLI",
      title: "Download the CLI",
      required: false,
      bodyText: `<p>To install the CLI (Command Line Interface), run the following command.</p><p>For more info about our CLI check out our <a href="${intl.formatMessage(
        { id: "links.documentation.CLI" }
      )}" target="_blank" rel="noopener noreferrer">documentation</a> or take a look at our <a href=${intl.formatMessage(
        { id: "links.CLI_repository" }
      )} target="_blank" rel="noopener noreferrer">CLI source code</a> which provides alternate installation methods.</p>`,
      copyCode: [
        {
          label:
            "OSX or Linux Installer and Windows (with Windows Subsystem for Linux)",
          code: [
            `curl -sS ${intl.formatMessage({ id: "links.CLI_download" })} | php`
          ]
        }
      ]
    },
    {
      id: "sshKey",
      label: "Add your SSH key",
      title: "Add your SSH key",
      required: true,
      bodyText: `<p>To deploy your code to ${intl.formatMessage({
        id: "app_name"
      })} you'll need Git installed on your local machine and your <a href=${intl.formatMessage(
        { id: "links.documentation.SSH_keys" }
      )} target="_blank" rel="noopener noreferrer">SSH key</a>. You can paste your Public SSH key in the box below (or do so later in your Account Settings).</p><p>Configuring an SSH key will also allow you to connect to your running applications and services.</p><p>If you don't have an SSH key configured we recommend following GitHub's excellent SSH <a href="https://help.github.com/en/articles/generating-a-new-ssh-key-and-adding-it-to-the-ssh-agent" target="_blank" rel="noopener noreferrer">tutorial</a>.</p>`
    },
    {
      id: "remote",
      label: `Set ${intl.formatMessage({
        id: "app_name"
      })} remote`,
      title: `Set ${intl.formatMessage({
        id: "app_name"
      })} as a remote so you can push code to ${intl.formatMessage({
        id: "app_name"
      })}`,
      required: true,
      bodyText: `<p>If you have a local Git repository already available, add your ${intl.formatMessage(
        {
          id: "app_name"
        }
      )} repository as a new remote using the ${intl.formatMessage({
        id: "app_name"
      })} CLI <code>project:set-remote</code> command. If you have not already initialized your project directory as a Git repository, you will need to do this first.</p><p>Visit our <a href=${intl.formatMessage(
        { id: "links.documentation.create_new_project" }
      )} target="_blank" rel="noopener noreferrer">docs</a> for more information.</p>`,
      copyCode: [
        {
          label: "Set Git remote",
          code: [
            `${intl.formatMessage({
              id: "CLI_command_name"
            })} project:set-remote <project ID>`
          ]
        }
      ]
    },
    {
      id: "yaml",
      label: "Configure your project",
      title: `To deploy your project you must first add ${intl.formatMessage({
        id: "app_name"
      })} configuration YAML files`,
      required: true,
      bodyText: `<p>You can start by creating empty YAML files. In the next steps we will help you fill them in.</p><p><a href=${intl.formatMessage(
        { id: "links.documentation.yaml_configuration" }
      )} target="_blank" rel="noopener noreferrer">Learn more</a> about configuring the YAML files</p>`,
      copyCode: [
        {
          label: "Application code structure",
          code: [
            " ├── .platform",
            " │   ├── routes.yaml",
            " │   └── services.yaml",
            " ├── .platform.app.yaml",
            " └── < application code >"
          ],
          codeType: "noCopy"
        },
        {
          label: "Create empty files",
          code: [
            "mkdir .platform/",
            "touch .platform/routes.yaml",
            "touch .platform/services.yaml",
            "touch .platform.app.yaml"
          ]
        }
      ]
    },
    {
      id: "services",
      label: "Configure services",
      title: "Configure your services",
      required: false,
      bodyText: `<p>${intl.formatMessage({
        id: "app_name"
      })} comes with fully managed services with databases such as MySQL or Postgres, caches like Redis or Varnish and search-engines like Solr. You can leave this blank for now and configure them later or use the dropdown to compose your <code>services.yaml</code> file with code examples. Your sample file will include the latest versions we offer.</p><p><a href=${intl.formatMessage(
        { id: "links.documentation.services" }
      )} target="_blank" rel="noopener noreferrer">Learn more</a> about configuring your services.</p>`
    },
    {
      id: "application",
      label: "Configure applications",
      title: "Configure your application",
      required: true,
      bodyText: `<p>The <code>.platform.app.yaml</code> file defines how your application is built, deployed and run. You should define at a minimum: a unique <code>name</code>, <code>type</code> (the runtime), the size of your persistent <code>disk</code> and <code>web</code>.</p><p>If you configured services in the previous step, pay attention to how those services are defined in the <code>relationships</code> block.</p><p>Take a look at our <a href=${intl.formatMessage(
        { id: "links.documentation.container_configuration" }
      )} target="_blank" rel="noopener noreferrer">documentation</a> for more detailed examples and for instructions on deploying multiple applications and workers in your cluster.</p>`
    },
    {
      id: "routes",
      label: "Configure routes",
      title: "Configure the HTTP router",
      required: true,
      bodyText: `<p>${intl.formatMessage({
        id: "app_name"
      })} supports multiple applications in a single cluster as well as advanced caching and redirects. The <code>.platform/routes.yaml</code> file defines those. The example below routes all the requests to a single application and redirects the <pre>www.</pre> subdomain to the root domain.</p><p><a href=${intl.formatMessage(
        {
          id: "links.documentation.routes"
        }
      )} target="_blank" rel="noopener noreferrer">Learn more</a> about configuring your routes.</p>`
    },
    {
      id: "push",
      label: "Commit & push",
      title: `Commit and push your configuration files to ${intl.formatMessage({
        id: "app_name"
      })}`,
      required: true,
      bodyText: `<p>To deploy on ${intl.formatMessage({
        id: "app_name"
      })} add the required configuration files to your Git repository: </p><p><label>Required files:</label><br><a href=${intl.formatMessage(
        { id: "links.documentation.container_configuration" }
      )} target="_blank" rel="noopener noreferrer"><code>.platform.app.yaml</code></a>, <a href=${intl.formatMessage(
        { id: "links.documentation.services" }
      )} target="_blank" rel="noopener noreferrer"><code>.platform/services.yaml</code></a>, and <a href=${intl.formatMessage(
        {
          id: "links.documentation.routes"
        }
      )} target="_blank" rel="noopener noreferrer"><code>.platform/routes.yaml</code></a></p><p>Then commit and push them using the following commands:</p><br><p>See our <a href=${intl.formatMessage(
        { id: "links.documentation.own_code_project_configuration" }
      )} target="_blank" rel="noopener noreferrer">documentation</a> for more information.</p>`,
      copyCode: [
        {
          label: "Commit and push",
          code: [
            "git add .",
            'git commit -m "Add config files."',
            `git push -u ${intl.formatMessage({
              id: "CLI_command_name"
            })} master`
          ],
          multiLine: true
        },
        {
          label: "Verify deployment",
          code: [
            `${intl.formatMessage({
              id: "CLI_command_name"
            })} url`
          ]
        }
      ]
    },
    {
      id: "database",
      label: "Import existing database",
      title: `Import an existing database to ${intl.formatMessage({
        id: "app_name"
      })}`,
      required: false,
      bodyText: `<p>If you run <a href=${intl.formatMessage({
        id: "links.documentation.mysql_import_data"
      })} target="_blank" rel="noopener noreferrer">MySQL</a> or <a href=${intl.formatMessage(
        {
          id: "links.documentation.postgres_import_data"
        }
      )} target="_blank" rel="noopener noreferrer">Postgres</a>, you can use the CLI’s <code>${intl.formatMessage(
        {
          id: "CLI_command_name"
        }
      )} sql</code> command to pipe a database dump onto the ${intl.formatMessage(
        {
          id: "app_name"
        }
      )} database.</p><p>Check our <a href=${intl.formatMessage({
        id: "links.documentation.migrating_tutorial"
      })}>documentation</a> to find-out more about data migration.</p>`,
      copyCode: [
        {
          label: "Run the commands",
          code: [
            `${intl.formatMessage({
              id: "CLI_command_name"
            })} sql < my_database_snapshot.sql`
          ]
        }
      ]
    },
    {
      id: "files",
      label: "Import additional files",
      title: "Import additional files",
      required: false,
      bodyText: `<p>${intl.formatMessage({
        id: "app_name"
      })} supports persistent mounts to which your applications can write. These are defined in the <code>.platform.app.yaml</code> file.</p><p>If you have existing files you want to upload the CLI can guide you through the process. </p>`,
      copyCode: [
        {
          label: "Import files",
          code: [
            `${intl.formatMessage({
              id: "CLI_command_name"
            })} mount:upload --mount web/uploads --source ./local_files`
          ]
        }
      ]
    }
  ];
};

export default getDefaultBlankWizardSteps;
