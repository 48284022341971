import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Map } from "immutable";
import { projectSelector } from "Reducers/project";

export const loadProjectCapability = createAsyncThunk(
  "app/project/capability/load",
  async ({ organizationId, projectId }, { getState }) => {
    const project = projectSelector(getState(), {
      organizationId,
      projectId
    });
    const capabilities = await project.getCapabilities();
    return { capabilities, projectId, organizationId };
  }
);

const projectCapabilities = createSlice({
  name: "capabilities",
  initialState: Map(),
  extraReducers: {
    [loadProjectCapability.pending]: state => state.set("loading", true),
    [loadProjectCapability.fulfilled]: (state, { payload }) => {
      const { capabilities, projectId, organizationId } = payload;

      return state
        .setIn([organizationId, projectId], capabilities)
        .set("loading", false);
    },
    [loadProjectCapability.rejected]: state => state.set("loading", false)
  }
});

export const projectCapabilitySelector = (
  state,
  { organizationId, projectId }
) => state.capabilities?.getIn([organizationId, projectId]);

export default projectCapabilities.reducer;
