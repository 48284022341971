import React from "react";
import PropTypes from "prop-types";
import CustomScroll from "react-custom-scroll";
import { FormattedMessage } from "react-intl";
import { Link as ReactRouterLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { Map } from "immutable";

import useSelectorWithUrlParams from "Hooks/useSelectorWithUrlParams";
import { environmentSelector } from "Reducers/environment";

import Link from "Components/styleguide/Link";

import LockIcon from "Components/icons/LockIcon";

import VisuallyHidden from "Components/VisuallyHidden";
import AccessibleTooltip from "Components/AccessibleTooltip";

import * as S from "./styles";

const EnvironmentRoutes = ({
  environmentId,
  organizationId,
  projectId,
  hideRedirects,
  routeMaxNumber = 100
}) => {
  const environment = useSelectorWithUrlParams(environmentSelector, {
    organizationId,
    projectId,
    environmentId
  });

  const routes = useSelector(state => {
    const deploymentRoutes = Object.entries(
      state.deployment
        ?.getIn(
          [
            "data",
            organizationId,
            projectId,
            environmentId,
            "current",
            "routes"
          ],
          Map()
        )
        ?.toJS()
    )
      .filter(([, a]) => a.type !== "redirect")
      .map(([key]) => key);

    let environmentRoutes = [];
    if (typeof environment?.getRouteUrls === "function") {
      environmentRoutes = environment.getRouteUrls();
    }

    return hideRedirects ? deploymentRoutes : environmentRoutes;
  });

  const securePath = path => {
    return path.startsWith("https");
  };

  const sortRoutes = (a, b) => {
    const urlA = a.toUpperCase();
    const urlB = b.toUpperCase();
    if (urlA < urlB) return -1;
    if (urlA > urlB) return 1;
    return 0;
  };

  const { httpsList, httpList } = routes?.reduce(
    (urls, url) => {
      if (url.includes("*")) return urls;
      if (securePath(url)) {
        urls.httpsList.push(url);
        return urls;
      }
      urls.httpList.push(url);
      return urls;
    },
    { httpsList: [], httpList: [] }
  );

  const routeList = httpsList
    .sort(sortRoutes)
    .concat(httpList.sort(sortRoutes));
  const slicedRouteList = routeList.slice(0, routeMaxNumber);
  const list = slicedRouteList.map((route, index) => (
    <S.RouteLink key={route}>
      <a
        id={`${environmentId}-route-link-${index}`}
        className={`route-link${securePath(route) ? " secure" : ""}`}
        href={route}
        target="_blank"
        rel="noopener noreferrer"
        role="menuitem"
        aria-label={route}
      >
        <span className="path">{route}</span>
        <VisuallyHidden>Opens a new window.</VisuallyHidden>
        {securePath(route) && (
          <AccessibleTooltip
            className="secure-icon"
            tooltipProps={{
              id: `${environmentId}-route-link-${index}-tooltip`,
              children: "SSL encrypted"
            }}
          >
            <LockIcon iconId={`${environmentId}-route-link-${index}`} />
          </AccessibleTooltip>
        )}
      </a>
    </S.RouteLink>
  ));

  return (
    <S.RoutesWrapper hasViewAll={hideRedirects || routeList.length > 8}>
      {slicedRouteList.length > 8 ? (
        <CustomScroll heightRelativeToParent="248px">
          <S.RouteList>{list}</S.RouteList>
        </CustomScroll>
      ) : (
        <S.RouteList>{list}</S.RouteList>
      )}
      {(hideRedirects || routeList.length > 8) && (
        <S.ViewAll>
          <Link
            as={ReactRouterLink}
            to={`/${organizationId}/${projectId}/${encodeURIComponent(
              environmentId
            )}/services/routes`}
          >
            <FormattedMessage id="environment.routes.viewall" />
          </Link>
        </S.ViewAll>
      )}
    </S.RoutesWrapper>
  );
};

EnvironmentRoutes.propTypes = {
  environmentId: PropTypes.string,
  organizationId: PropTypes.string,
  projectId: PropTypes.string,
  routeMaxNumber: PropTypes.number,
  hideRedirects: PropTypes.bool
};

export default EnvironmentRoutes;
