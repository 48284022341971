import PropTypes from "prop-types";
import React from "react";
import { useIntl } from "react-intl";
import { Button } from "@platformsh/ui-kit";

import ButtonWrapper from "Components/ButtonWrapper";

import * as S from "../SourceOperation.style";

export const UpgradeSourceOps = ({ onClose }) => {
  const formatMessage = useIntl().formatMessage;
  return (
    <S.SourceOperationContainer>
      <S.Heading>{formatMessage({ id: "source_ops.upgrade.title" })}</S.Heading>
      <S.OperationDescription className="operation-notice">
        {formatMessage({ id: "source_ops.upgrade.message" })}
      </S.OperationDescription>

      <ButtonWrapper className="modal-buttons operation-notice-action-button">
        <Button
          as="a"
          target="_blank"
          href={formatMessage({ id: "links.pricing" })}
        >
          {formatMessage({ id: "view_pricing" })}
        </Button>

        <Button variant="secondary" onClick={onClose}>
          {formatMessage({ id: "close" })}
        </Button>
      </ButtonWrapper>
    </S.SourceOperationContainer>
  );
};

UpgradeSourceOps.propTypes = {
  onClose: PropTypes.func
};

export default UpgradeSourceOps;
