import styled, { css } from "styled-components";
import { getCSSVarString, SECTION } from "Libs/themes";

const LEVELS = {
  info: {
    color:"var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)))",
    background:"var(--section-skye-light-background-color,var(--section-skye-light,var(--skye-light)))",
    border:"var(--section-skye-border-color,var(--section-skye,var(--skye)))"
  },
  low: {
    color:"var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)))",
    background:"var(--section-cosmic-latte-background-color,var(--section-cosmic-latte,var(--cosmic-latte)))",
    border:"var(--section-java-border-color,var(--section-java,var(--java)))"
  },
  warning: {
    color:"var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)))",
    background:"var(--section-tulip-pedals-background-color,var(--section-tulip-pedals,var(--tulip-pedals)))",
    border:"var(--section-gold-border-color,var(--section-gold,var(--gold)))"
  },
  error: {
    color:"var(--section-ebony-color,var(--section-ebony,var(--ebony)))",
    background:"var(--section-misty-rose-background-color,var(--section-misty-rose,var(--misty-rose)))",
    border:"var(--section-red-border-color,var(--section-red,var(--red)))"
  }
};

const __dash = (color, direction) => `
  repeating-linear-gradient(
    to ${direction},
    ${color} 0%,
    ${color} 35%,
    transparent 35%,
    transparent 55%,
    ${color} 55%,
    ${color} 75%,
    transparent 75%,
    transparent 100%
  )
`;

const getCustomBorder = color => css`
  background-image: ${__dash(color, "right")}, ${__dash(color, "right")},
    ${__dash(color, "bottom")}, ${__dash(color, "bottom")};
`;

export default styled.div`
  font-size: 13px;
  line-height: 20px;
  box-sizing: border-box;
  padding: ${props => props.padding || "32px"};
  margin-bottom: 32px;
  border-radius: 2px;
  background: ${props => LEVELS[props.level || "info"].background};
  ${props => getCustomBorder(LEVELS[props.level || "info"].border)};
  background-position: left top, left bottom, left top, right top;
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 24px 1px, 24px 1px, 1px 24px, 1px 24px;
  color: ${props => LEVELS[props.level || "info"].color};
  p {
    color: ${props => LEVELS[props.level || "info"].color};
  }
`;
