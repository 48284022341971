import styled from "styled-components";
import { semiBoldAlias } from "Libs/theme";
import { css, SECTION, ICON, getCSSVarString } from "Libs/themes";

import { WingsDialog } from "../EnvironmentListBar/EnvironmentListBar.styles";

export const Wrapper = styled.div`
  position: relative;
  .environment-line a {
    position: relative;
    margin: 0 -32px;
    padding: 0 32px;
    line-height: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    box-sizing: inherit;
    &:hover,
    &:focus {
      border-radius: 0;
      outline: none;
    }
    .list-bar-right {
      line-height: 32px;
      width: 76px;
      @media (min-width: 1280px) {
        width: 120px;
      }
    }
  }
  .info-button.website-link {
    top: 12px;
  }
  .environment-line ${WingsDialog} {
    top: 15px;
  }
  &.depth-0,
  &.depth-1 {
    .list-bar-left {
      line-height: 32px;
      .icon {
        min-width: 32px;
        width: 32px;
        height: 32px;
        &.root {
          background-color:var(--icon-java-background-color,var(--icon-java,var(--java))) !important;
          svg path {
            fill:var(--root-environment-icon-fill,var(--icon-snow-fill,var(--icon-snow,var(--snow))));
          }
        }
      }
    }
    .title {
      ${semiBoldAlias};
    }
  }
  &.depth-1 {
    .list-bar-left .icon {
      min-width: 24px;
      width: 24px;
      height: 24px;
      @media (min-width: 1130px) {
        min-width: 32px;
        width: 32px;
        height: 32px;
      }
    }
  }
  .list-bar-left {
    .icon {
      svg path {
        fill:var(--no-children-environment-icon-fill,var(--icon-skye-fill,var(--icon-skye,var(--skye))));
      }
      &.inactive {
        svg path {
          fill:var(--icon-granite-fill,var(--icon-granite,var(--granite)));
        }
      }
    }
  }
  .inactive {
    .title {
      color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
    }
  }
  &.has-children {
    .list-bar-left {
      .icon {
        background-color:var(--icon-skye-background-color,var(--icon-skye,var(--skye)));
        svg path {
          fill:var(--has-children-environment-icon-fill,var(--icon-snow-fill,var(--icon-snow,var(--snow))));
        }
        &.inactive {
          background: ${getCSSVarString(
            ICON,
            "grey",
            "background-color"
          )} !important;
          svg path {
            fill:var(--icon-granite-fill,var(--icon-granite,var(--granite)));
          }
        }
      }
    }
  }
`;
