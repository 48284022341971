import React, { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import CustomScroll from "react-custom-scroll";
import { LiveMessage } from "react-aria-live";

import { sortBy } from "Libs/utils";

import useDecodedParams from "Hooks/useDecodedParams";
import useSelectorWithUrlParams from "Hooks/useSelectorWithUrlParams";

import { environmentTreeSelector } from "Reducers/environment";

import { List, Menu, NavBar, Node } from "./";
import ChevronIcon from "Icons/ChevronIcon";
import Label from "Components/fields/Label";
import useLocalForage from "Hooks/useLocalForage";

import { loadSettings, persistSettings } from "./persist-settings";

import * as S from "./EnvironmentTree.styles";

const getAllEnvironments = data => {
  if (!data) return [];

  return data.reduce((acc, current) => {
    if (!current) return acc;
    return acc.concat(current, getAllEnvironments(current.children));
  }, []);
};

const EnvironmentTree = ({ closeDropDown, data, layout }) => {
  const { environmentId, organizationId, projectId } = useDecodedParams();
  const [listLayout = false, setListLayout, , isLoadingLayout] = useLocalForage(
    "projects_environment-list_layout"
  );

  const [sortType, setSortType] = useState();
  const [sortOrder, setSortOrder] = useState();
  const [showInactive, setShowInactive] = useState();
  const [showActive, setShowActive] = useState();
  const [titleFilter, setTitleFilter] = useState("");

  const [environments, setEnvironments] = useState([]);

  const [createdSort, setCreatedSort] = useState();
  const [nameSort, setNameSort] = useState();

  const ref = useRef();

  const envTree = useSelectorWithUrlParams(environmentTreeSelector)?.toArray();

  useEffect(() => {
    let isCanceled = false;
    const setDefaultSettings = async () => {
      const settings = await loadSettings(projectId);
      if (isCanceled) {
        return;
      }
      setSortType(settings.sortType || "name");
      setSortOrder(settings.sortOrder || "ascend");
      setCreatedSort(settings.sortOrder || "ascend");
      setNameSort(settings.sortOrder || "ascend");
      setShowActive(
        settings.showActive !== undefined ? settings.showActive : true
      );
      setShowInactive(
        settings.showInactive !== undefined ? settings.showInactive : true
      );
    };
    setDefaultSettings();

    return () => (isCanceled = true);
  }, [projectId]);

  useEffect(() => {
    let filters = {
      showActive,
      showInactive,
      sortType,
      sortOrder: sortType === "name" ? nameSort : createdSort
    };
    Object.keys(filters).forEach(
      key => filters[key] === undefined && delete filters[key]
    );
    persistSettings(projectId, filters);
  }, [showActive, showInactive, sortOrder, sortType]);

  useEffect(() => {
    const envs = getAllEnvironments(data);
    setEnvironments(envs);
  }, [data]);

  const toggleSort = (value, event) => {
    event?.preventDefault();
    let direction;
    switch (value) {
      case "name":
        direction = nameSort;
        if (sortType === "name") {
          direction = nameSort === "ascend" ? "descend" : "ascend";
        }
        setNameSort(direction);
        break;
      case "created":
        direction = createdSort;
        if (sortType === "created") {
          direction = createdSort === "ascend" ? "descend" : "ascend";
        }
        setCreatedSort(direction);
        break;
    }
    setSortOrder(direction);
    setSortType(value);
  };

  const activeEnvironments = environments.filter(
    i => i.status !== "inactive"
  ).length;

  const compactView =
    (showInactive && environments.length > 19) ||
    (!showInactive && activeEnvironments > 19);

  const filteredEnvs = useMemo(() => {
    if (!listLayout) return false;
    let list = sortBy(
      environments,
      sortType === "created" ? "created_at" : "title"
    );
    if (sortOrder === "descend") list = list.reverse();
    return list.filter(node => {
      if (titleFilter) {
        return node.title.toLowerCase().includes(titleFilter.toLowerCase());
      }
      return true;
    });
  }, [listLayout, sortType, sortOrder, titleFilter]);

  const visibleNodes = useMemo(() => {
    if (listLayout) return false;

    let list = envTree || [];
    if (titleFilter) {
      list = list.filter(env =>
        env.get("id").includes(titleFilter.toLowerCase())
      );
    }
    return list.reduce((acc, cu) => {
      acc = [
        ...new Set(acc.concat(cu.get("path")?.toArray() || [], cu.get("id")))
      ];
      return acc;
    }, []);
  }, [listLayout, titleFilter]);

  const countEnv = arr =>
    arr.reduce((acc, { children = [] }) => {
      acc++;
      if (children.length) acc += countEnv(children);
      return acc;
    }, 0);

  const listEnvironments = listLayout ? filteredEnvs : data;

  const classNames = (() => {
    let list = [];
    if (!showActive) list.push("hide-active");
    if (showInactive) list.push("show-inactive");
    if (!showActive && !showInactive) list.push("hide-all");
    if (compactView) list.push("compact");
    if (titleFilter) list.push("search-results");
    return list.join(" ");
  })();

  const scrollEvent = new Event("scrolled");

  return (
    <S.Layout ref={ref}>
      <LiveMessage
        message={`displaying environments as a ${listLayout ? "list" : "tree"}`}
        aria-live="polite"
      />
      <LiveMessage
        message={`inactive environments ${showInactive ? "visible" : "hidden"}`}
        aria-live="polite"
      />
      <LiveMessage
        message={`active environments ${showActive ? "visible" : "hidden"}`}
        aria-live="polite"
      />

      {titleFilter && (
        <LiveMessage
          message="environment list has changed based on search"
          aria-live="polite"
        />
      )}

      {layout === "menu" ? (
        <S.EnvironmentsMenuWrapper className="environments-menu">
          <Menu
            id="environment-branch"
            list={environments}
            tree={data}
            organizationId={organizationId}
            currentEnvironment={environmentId}
            closeDropDown={closeDropDown}
          />
        </S.EnvironmentsMenuWrapper>
      ) : (
        <>
          {!isLoadingLayout && (
            <NavBar
              treeLayout={!listLayout}
              setTreeLayout={v => setListLayout(!v)}
              titleFilter={titleFilter}
              setTitleFilter={setTitleFilter}
              showActive={showActive}
              setShowActive={setShowActive}
              showInactive={showInactive}
              setShowInactive={setShowInactive}
              sortType={sortType}
              toggleSort={toggleSort}
            />
          )}

          {listLayout ? (
            <S.TreeWrapper>
              <div className={`environments-list ${classNames}`}>
                {listLayout && (
                  <S.SortWrapper className="sort-heading">
                    <Link
                      onClick={e => toggleSort("name", e)}
                      className={`label label-name ${
                        sortType === "name" ? "active" : ""
                      }`}
                      to="#"
                      tabIndex="0"
                    >
                      <Label>Title</Label>
                      <span className={nameSort}>
                        <ChevronIcon />
                      </span>
                    </Link>
                    <Label className="label label-url">URLs</Label>
                    <Link
                      onClick={e => toggleSort("created", e)}
                      className={`label label-updated ${
                        sortType === "created" ? "active" : ""
                      }`}
                      to="#"
                      tabIndex="0"
                    >
                      <Label>Created</Label>
                      <span className={createdSort}>
                        <ChevronIcon />
                      </span>
                    </Link>
                  </S.SortWrapper>
                )}
                <div>
                  <CustomScroll
                    {...(listEnvironments.length > 12 && {
                      heightRelativeToParent: "706px"
                    })}
                    allowOuterScroll
                    onScroll={() => document.dispatchEvent(scrollEvent)}
                  >
                    {listEnvironments.map(node => (
                      <List
                        key={node.title}
                        node={node}
                        organizationId={organizationId}
                        titleFilter={titleFilter}
                      />
                    ))}
                  </CustomScroll>
                </div>
              </div>
            </S.TreeWrapper>
          ) : (
            <CustomScroll
              {...(countEnv(listEnvironments) > 14 && {
                heightRelativeToParent: "735px"
              })}
              onScroll={() => document.dispatchEvent(scrollEvent)}
              allowOuterScroll
            >
              <S.TreeWrapper className={classNames}>
                <LiveMessage
                  message="displaying environments as a tree"
                  aria-live="polite"
                />
                <ol>
                  <Node
                    compactView={compactView}
                    nodes={listEnvironments}
                    organizationId={organizationId}
                    visibleNodes={visibleNodes}
                    titleFilter={titleFilter}
                  />
                </ol>
              </S.TreeWrapper>
            </CustomScroll>
          )}
        </>
      )}
    </S.Layout>
  );
};

EnvironmentTree.propTypes = {
  closeDropDown: PropTypes.func,
  data: PropTypes.array,
  layout: PropTypes.string
};

export default EnvironmentTree;
