import * as Sentry from "@sentry/react";

const sendErrorsToSentry =
  process.env.NODE_ENV === "production" && process.env.ENABLE_SENTRY;

if (sendErrorsToSentry) {
  const dsn = "https://8051dcccac49481f974eba00e1e1a838@sentry.io/1180480";
  Sentry.init({
    beforeSend: function (event, hint) {
      // Groups errors that are similar but have different stack traces
      // Fall back to default grouping if other values do not exist
      const fingerprint =
        event.contexts?.Context?.action ||
        event.contexts?.Context?.sourceError ||
        hint.originalException ||
        "{{ default }}";
      event.fingerprint = [fingerprint];

      return event;
    },
    dsn
  });
}

/**
 * Logs an error and sends it to Sentry, if enabled.
 *
 * @param {Error|Object} ex exception to log. It has to be an Error or an object containing a title or message properties
 * @param {Object} context any extra metadata
 */
function logException(ex, context) {
  if (sendErrorsToSentry) {
    let exception = ex;
    let extra = {
      ...context
    };

    if (!(ex instanceof Error)) {
      const message =
        typeof ex === "object" ? ex.message || ex.title || ex.errMessage : ex;
      extra = {
        ...extra,
        sourceError: ex
      };
      exception = new Error(message);
    }

    Sentry.setContext("Context", extra);
    Sentry.captureException(exception);
  }

  /*eslint no-console:0*/
  window.console && console.error && console.error(ex);
}

export default logException;
