import styled from "styled-components";
import { css, SECTION } from "Libs/themes";

export default styled.div`
  color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
  font-size: 15px;
  h3 {
    margin: 16px 0 18px;
  }
  p {
    line-height: 20px;
    margin: 0 0 5px;
  }
  .modal-buttons {
    padding-top: 32px;
  }

  h4 {
    display: flex;
    align-items: center;
  }
`;
