import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import styled, { keyframes, withTheme } from "styled-components";
import VisuallyHidden from "Components/VisuallyHidden";
import SearchIcon from "Icons/SearchIcon";
import { css, getCSSVarString, MENU, INPUT, ICON } from "Libs/themes";

const expandOut = keyframes`
  0% {
    width: 0;
    left: 50%;
  }
  100% {
    width: 100%;
    left: 0;
  }
`;

const expandIn = keyframes`
  0% {
    width: 100%;
    left: 0;
  }
  100% {
    width: 0;
    left: 50%;
  }
`;

const NavBarSearchInputLayout = styled.form`
  display: flex;
  align-items: center;
  padding: 0 24px;
  background-color:var(--menu-ice-background-color,var(--menu-ice,var(--ice)));
  position: relative;
  box-shadow: 0 1px 0
    var(--menu-periwinkle-grey-box-shadow-color,var(--menu-periwinkle-grey,var(--periwinkle-grey)));
  border-radius: 2px 2px 0 0;
  height: 56px;
  box-sizing: border-box;
  position: relative;
  .search-image {
    width: 24px;
    height: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    opacity: ${props => (props.theme.name === "dark" ? "1" : "0.5")};
    position: absolute;
    top: 16px;
    left: 28px;
  }
  &.float {
    background: transparent;
    padding-left: 0;
    padding-right: 0;
    border: none;
    box-shadow: none;
    .search-image {
      left: 0;
      top: 4px;
    }
    input.search {
      border: none;
      background: transparent;
      padding-left: 32px;
      &::placeholder {
        color: ${props =>
          props.theme.name === "dark" ? "#c9d0e4" : "inherit"};
      }
      &:focus {
        box-shadow: none;
      }
    }
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      height: 1px;
      width: 0;
      background-color:var(--menu-periwinkle-grey-background-color,var(--menu-periwinkle-grey,var(--periwinkle-grey)));
      animation: ${expandIn} 0.18s linear 1;
    }
    &:focus-within,
    &.search-active,
    &.has-value {
      position: relative;
      &:after {
        left: 0;
        width: 100%;
        animation: ${expandOut} 0.1s linear 1;
      }
    }
    &.has-value {
      &:after {
        background-color:var(--menu-periwinkle-grey-background-color,var(--menu-periwinkle-grey,var(--periwinkle-grey)));
      }
      .search-image {
        path {
          fill: ${props => (props.theme.name === "dark" ? "#ffffff" : "")};
        }
      }
    }
  }
  &:focus-within {
    .search-image {
      opacity: 1;
    }
    input::placeholder {
      color:var(--menu-ebony-light-color,var(--menu-ebony-light,var(--ebony-light)));
      opacity: ${props => (props.theme.name === "dark" ? "1" : "0.5")};
    }
  }
`;

const Input = styled.input`
  width: 200px;
  max-width: 100%;
  box-sizing: border-box;
  background: transparent;
  border: none;
  color:var(--input-ebony-light-color,var(--input-ebony-light,var(--ebony-light)));
  font-size: 14px;
  height: 32px;
  line-height: 32px;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0 8px 0 32px;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  &:focus {
    outline: none;
    border: 1px solid var(--input-skye-dark-border-color,var(--input-skye-dark,var(--skye-dark)));
    box-shadow: 0 0 2px
      var(--input-skye-dark-box-shadow-color,var(--input-skye-dark,var(--skye-dark)));
  }
  &.active,
  &:active {
    border-color: #98a0ab;
    background-color:var(--input-ice-background-color,var(--input-ice,var(--ice)));
    color:var(--input-night-color,var(--input-night,var(--night)));
    box-shadow: none;
  }
  &::placeholder {
    color:var(--input-ebony-light-color,var(--input-ebony-light,var(--ebony-light)));
    opacity: 1;
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
  }
`;

class SearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      changeStyle: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.changeStyle = this.changeStyle.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  }

  handleClickOutside(e) {
    if (!ReactDOM.findDOMNode(this).contains(e.target)) {
      this.setState({
        changeStyle: false
      });
    }
  }
  handleSubmit(event) {
    event.preventDefault();
  }
  changeStyle() {
    this.setState({
      changeStyle: true
    });
  }
  render() {
    const { hidden, marginLeft, id, placeholder, className } = this.props;
    return (
      <NavBarSearchInputLayout
        hidden={hidden}
        marginLeft={marginLeft}
        className={`search-wrapper${className ? " " + className : ""}${
          this.props && this.props.value ? " has-value" : ""
        }${this.state.changeStyle ? " search-active" : ""}`}
        role="search"
        aria-hidden={hidden}
        onSubmit={event => this.handleSubmit(event)}
        autocomplete="nope"
      >
        <span className="search-image">
          <SearchIcon
            id={id}
            alt="magnify glass"
            color={"var(--icon-ebony-light-fill,var(--icon-ebony-light,var(--ebony-light)))"}
          />
        </span>
        {/* eslint jsx-a11y/label-has-for: 0 */}
        <label htmlFor={id}>
          <VisuallyHidden>{placeholder}</VisuallyHidden>
        </label>
        <Input
          {...this.props}
          id={id}
          name={id}
          aria-label={placeholder}
          type="text"
          autoComplete="off"
          className={`search${
            (this.props && this.props.value && this.props.value.length !== 0) ||
            this.state.changeStyle
              ? " active"
              : ""
          }`}
          onClick={this.changeStyle}
        />
      </NavBarSearchInputLayout>
    );
  }
}

SearchInput.propTypes = {
  id: PropTypes.string,
  placeholder: PropTypes.string,
  hidden: PropTypes.bool,
  iconSize: PropTypes.number,
  marginLeft: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  value: PropTypes.string,
  theme: PropTypes.object
};

export default withTheme(SearchInput);
